const actions = {
  SET_LOADING: 'SET_LOADING',
  SET_START_SESSION: 'SET_START_SESSION',
  SET_SURVEY_COMPLETED: 'SET_SURVEY_COMPLETED',
  SET_ERROR: 'SET_ERROR',
  SET_EXPIRED: 'SET_EXPIRED',
  SET_SURVEY_CANCELLED: 'SET_SURVEY_CANCELLED',
  SET_SURVEY_EXPIRED: 'SET_SURVEY_EXPIRED',

  setLoadingAction: data => {
    return {
      type: actions.SET_LOADING,
      data,
    };
  },

  setStartSessionAction: data => {
    return {
      type: actions.SET_START_SESSION,
      data,
    };
  },

  setSurveyCompletedAction: data => {
    return {
      type: actions.SET_SURVEY_COMPLETED,
      data,
    };
  },
  setSurveyCancelledAction: data => {
    return {
      type: actions.SET_SURVEY_CANCELLED,
      data,
    };
  },

  setErrorAction: data => {
    return {
      type: actions.SET_ERROR,
      data,
    };
  },

  setExpiredAction: data => {
    return {
      type: actions.SET_EXPIRED,
      data,
    };
  },
  setSurveyExpiredAction: data => {
    return {
      type: actions.SET_SURVEY_EXPIRED,
      data,
    };
  },
};

export default actions;
