// eslint-disable-next-line import/no-anonymous-default-export
export default {
    screens: [
        {
            observation: 'honesty1',
            type: 'end_intro',
            module: 'trans_end',
            help: true,
            auto_advance: true,
            meta: {
                image: "transition/transEnd",
                hideHalpNavigation: true
            },
            timer: {
                time: 15000,
                extend: false,
                pause: true,
                visible: true
            },
            next: {
                rules: {
                    visible: true,
                    enabled: ['observation']
                },
                visible: true,
                enabled: false
            },
        },
        {
            observation: 'survey_satisfaction',
            type: 'servey_rating',
            module: 'trans_end',
            help: true,
            auto_advance: true,
            timer: {
                time: 15000,
                extend: false,
                pause: true,
                visible: true
            },
            next: {
                rules: {
                    visible: true,
                    enabled: ['observation']
                },
                visible: true,
                enabled: false
            },
            meta: {
                title: {
                    alignment: 'left'
                },
                center: true,
                options: [
                    {
                      value: 1
                    },
                    {
                      value: 2
                    },
                    {
                      value: 3
                    },
                    {
                      value: 4
                    },
                    {
                      value: 5
                    }
                ],
                imgSrc: [
                    "transition/verysatisfied",
                    "transition/satisfied",
                    "transition/netural",
                    "transition/dissatisfied",
                    "transition/Very dissatisfied"
                ],
                hideHalpNavigation: true,
            }
        },
        // {
        //     type: "servey_finish",
        //     observation: "survey_complete",
        //     module: "trans_end",
        //     meta: {
        //         image: 'transition/thankyou',
        //     },
        //     next: {
        //         rules: {
        //             visible: true,
        //             enabled: true
        //         }
        //     },
        //     auto_advance: true,
        //     timer: {
        //         time: 3000,
        //         extend: false,
        //         pause: false,
        //         visible: false
        //     },
        //     mobileLogoVisible: true,
        // }

    ],
    screen_submit:true
}