// eslint-disable-next-line import/no-anonymous-default-export
export default {
    screens: [
        {
            observation: 'cgp_intro',
            type: 'intro',
            module: 'coins_personal',
            meta: {
                v2: true,
                hiddenHeader: true,
                hideBottumNavigation: true,
                image: 'coinGames/newIntro',
                sub_text: true,
            },
            auto_advance: true,
            timer: {
                time: 10000,
                extend: false,
                pause: false,
                visible: false
            }
        },
        {
            observation: 'cgp_exercise',
            module: 'coins_personal',
            type: 'coin_game',
            help: true,
            timer: {
                time: 60000,
                extend: true,
                pause: true,
                visible: true,
                extentionPopup: 1,
                extentionButtonDesign: "true"
            },
            back: false,
            meta: {
                coinImg: ['coinGames/active_coin', 'coinGames/used_coin'],
                fields: ['Food', 'Transport', 'Communication (airtime, internet)', 'Water/Electricity', 'Entertainment', 'Clothing', 'Family support', 'Debt/Loans', 'Health', 'Savings', 'School fees', 'Rent/Mortgage', 'Insurance', 'Home improvement', 'Others'],
                coinSpend: [["cgp_food", 0], ["cgp_transport", 0], ["cgp_communication", 0], ["cgp_waterelectricity", 0], ["cgp_entertainment", 0], ["cgp_clothing", 0], ["cgp_familysupport", 0], ["cgp_debtloans", 0], ["cgp_health", 0], ["cgp_savings", 0], ["cgp_schoolfees", 0], ["cgp_rentmortgage", 0], ["cgp_insurance", 0], ["cgp_homeimprovement", 0], ["cgp_others", 0]],
                totleCoins: Array.from({ length: 20 }, () => true),
                helpNavigation: true,
                title: { alignment: 'left' },
                hideHalpNavigation: true,
                helpImgs: ['coinGames/cgp_help1', 'coinGames/cgp_help2']
            }
        },
        {
            type: "module_finish",
            observation: "greatJob",
            module: "coins_personal",
            auto_advance: true,
            timer: {
                time: 3000,
                extend: false,
                pause: false,
                visible: false,
            },
            next: {
                visible: true,
                enabled: true
            }
        }
    ],
    screen_submit: false
}