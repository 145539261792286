export const STATUS_CODE = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  PENDING: 'PENDING',
}
export const API_RESPONSE_CODE = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  FAILED: 'FAILED',
}
export const COOKIE = {
  TOKEN: 'token',
  USER: 'user'
}
export const URL = {
  SUCCESS_URL: null,
  FAILURE_URL: '/cancelled',
  EXPIRED_URL: '/assessment-expired'
}
export const RETRY = 'RETRY'