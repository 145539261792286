/* eslint-disable import/no-anonymous-default-export */
export default {
    screens: [
        {
            observation: 'bg_intro',
            type: 'intro',
            module: 'balloon_game',
            meta: {
                v2: true,
                hiddenHeader: true,
                image: 'balloon/bg_intro'
            },
            auto_advance: true,
            timer: {
                time: 10000,
                extend: false,
                pause: false,
                visible: false
            }
        },
        {
            observation: 'bg_exercise_0',
            type: 'balloon_module',
            module: 'balloon_game',
            meta: {
                generate: {
                    delay: 59999,
                },
                hideBottumNavigation: true,
                helpImgs: ['balloon/help/bg_help1', 'balloon/help/bg_help2', 'balloon/help/bg_help3'],
                images: ["balloon/frames/bg01", "balloon/frames/bg02", "balloon/frames/bg03", "balloon/frames/bg04", "balloon/frames/bg05", "balloon/frames/bg06", "balloon/frames/bg07", "balloon/frames/bg08", "balloon/frames/bg09", "balloon/frames/bg10", "balloon/frames/bg11", "balloon/frames/bg12", "balloon/frames/bg13", "balloon/frames/bg14", "balloon/frames/bg15"],
                InitScore: 2,
                IncrementScore: 5,
                containerName: 'balloon'
            },
            help: true,
            auto_advance: false,
            timer: {
                time: 60000,
                extend: false,
                pause: false,
                visible: true
            },
        },
        {
            observation: 'time_expire_sand_truck',
            module: 'balloon_game',
            type: 'time_expire_model',
            auto_advance: true,
            timer: {
                time: 3000,
                extend: false,
                pause: false,
                visible: false
            }
        },
        {
            observation: 'bg_score_0',
            type: 'score_board',
            module: "balloon_game",
            meta: {
                preObs: "bg_exercise_0",
                status: "dangar",
                image: "balloon/bg_pop",
                containerName: 'balloon'
            },
            next: {
                visible: true,
                enabled: true
            },
            auto_advance: true,
            timer: {
                time: 3000,
                extend: false,
                pause: false,
                visible: false
            }
        },
        {
            observation: 'bg_postguess',
            type: 'radio_group',
            meta: {
                center: true,
                defaultValue: null,
                title: {
                    alignment: 'left'
                },
                sub_title: {
                    alignment: 'left'
                },
                options: [
                    { value: "1" },
                    { value: "2" },
                    { value: "3" }
                ]
            },
            module: "balloon_game",
            help: true,
            next: {
                rules: {
                    visible: true,
                    enabled: ['observation']
                },
                visible: true,
                enabled: false
            },
        },
        {
            observation: 'bg_redo',
            type: 'radio_group',
            meta: {
                center: true,
                defaultValue: null,
                fontSize: '15px',
                title: {
                    alignment: 'left'
                },
                sub_title: {
                    alignment: 'left'
                },
                options: [
                    { value: "1" },
                    { value: "2" },
                    { value: "3" }
                ]
            },
            module: "balloon_game",
            help: true,
            next: {
                rules: {
                    visible: true,
                    enabled: ['observation']
                },
                visible: true,
                enabled: false
            },
        },
        {
            observation: 'bg_exercise_1',
            type: 'balloon_module',
            module: 'balloon_game',
            meta: {
                generate: {
                    delay: 59999,
                },
                hideBottumNavigation: true,
                helpImgs: ['balloon/help/bg_help1', 'balloon/help/bg_help2', 'balloon/help/bg_help3'],
                images: ["balloon/frames/bg01", "balloon/frames/bg02", "balloon/frames/bg03", "balloon/frames/bg04", "balloon/frames/bg05", "balloon/frames/bg06", "balloon/frames/bg07", "balloon/frames/bg08", "balloon/frames/bg09", "balloon/frames/bg10", "balloon/frames/bg11", "balloon/frames/bg12", "balloon/frames/bg13", "balloon/frames/bg14", "balloon/frames/bg15"],
                InitScore: 2,
                IncrementScore: 3,
                containerName: 'balloon'
            },
            help: true,
            auto_advance: false,
            timer: {
                time: 60000,
                extend: false,
                pause: true,
                visible: true
            }
        },
        {
            observation: 'time_expire_sand_truck',
            module: 'balloon_game',
            type: 'time_expire_model',
            auto_advance: true,
            timer: {
                time: 3000,
                extend: false,
                pause: false,
                visible: false
            }
        },
        {
            observation: 'bg_score_1',
            type: 'score_board',
            module: "balloon_game",
            meta: {
                preObs: "bg_exercise_1",
                status: "dangar",
                image: "balloon/bg_pop",
                containerName: 'balloon'
            },
            auto_advance: true,
            next: {
                visible: true,
                enabled: true
            },
            timer: {
                time: 3000,
                extend: false,
                pause: false,
                visible: false
            }
        },
        {
            type: "module_finish",
            observation: "great_job",
            module: "balloon_game",
            auto_advance: true,
            timer: {
                time: 3000,
                extend: false,
                pause: false,
                visible: false,
            },
            next: {
                visible: true,
                enabled: true
            }
        }
    ],
    skip_keys: {
        bg_redo: {
            values: ["3"],
            targets: ['bg_exercise_1', 'bg_score_1', 'time_expire_sand_truck']
        },
        bg_exercise_0: {
            values: ["danger", "success"],
            targets: ['time_expire_sand_truck']
        },
        bg_exercise_1: {
            values: ["danger", "success"],
            targets: ['time_expire_sand_truck']
        },
        bg_score_0: {
            values: ["dangar"],
            targets: ['bg_postguess']
        }
    },
    screen_submit: false
}