const actions = {
  SET_CLIENT: 'SET_CLIENT',
  SET_THEME: 'SET_THEME',
  SET_CALLBACKS: 'SET_CALLBACKS',

  setThemeAction: data => {
    return {
      type: actions.SET_THEME,
      data,
    };
  },

  setClientAction: data => {
    return {
      type: actions.SET_CLIENT,
      data,
    };
  },
  setCallbacksAction: data => {
    return {
      type: actions.SET_CALLBACKS,
      data,
    };
  },
};

export default actions;