import { Container } from '@material-ui/core';
import { useSelector } from 'react-redux';
import LoadingScreen from '../LoadingScreen';
import ProgressBar from '../ProgressBar/ProgressBar';

const Header = ({ mobileLogoVisible }) => {
  const { theme } = useSelector(st => st.config);

  const { startSession, expired, surveyCancelled, surveyExpired, loading } = useSelector(st => st.auth);
  const { endSessionApi, header } = useSelector(State => State.module);


  if (!startSession || expired || surveyCancelled || surveyExpired) {
    return ''
  }
  return (
    <>{loading || endSessionApi.loading ? <LoadingScreen /> : null}
      {!endSessionApi.loading && header && (
        <><header className={`header ${mobileLogoVisible ? '' : 'd-none'} d-md-flex`} style={{ background: theme.secondaryColor }}>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <div className='header-icon'>
                  <img src={theme.logo || process.env.REACT_APP_DEFAULT_PSY_LOGO}
                    height={theme.height} alt={theme.companyName} />
                </div>
              </div>
            </div>
          </div>
        </header>
          <Container maxWidth='lg' >
            <ProgressBar />
          </Container>
        </>)}
    </>
  )
}
export default Header
