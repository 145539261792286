// eslint-disable-next-line import/no-anonymous-default-export
export default {
    screens: [
        {
            observation: 'intro_disc',
            module: 'fin_des',
            type: 'intro',
            meta: {
                hiddenHeader: true,
                image: 'intro_disc',
                agentImg: true,
            },
            auto_advance: true,
            timer: {
                time: 5000,
                extend: false,
                pause: false,
                visible: false
            },
            next: {
                visible: true,
                enabled: true
            },
        },
        {
            observation: 'disc_framing0',
            type: 'multiple_instructions',
            help: false,
            meta: {
                instruction: {
                    alignment: 'left'
                },
                image: {
                    width: 80,
                    source: 'disc_framing0'
                }
            },
            auto_advance: true,
            timer: {
                time: 5000,
                extend: false,
                pause: false,
                visible: false
            },
            next: {
                visible: true,
                enabled: true
            },
            module: 'fin_des'
        },
        {
            observation: 'disc_framing1',
            type: 'multiple_instructions',
            help: false,
            meta: {
                instruction: {
                    alignment: 'left'
                },
                image: {
                    width: 80,
                    source: 'disc_framing1'
                }
            },
            auto_advance: true,
            timer: {
                time: 5000,
                extend: false,
                pause: false,
                visible: false
            },
            next: {
                visible: true,
                enabled: true
            },
            module: 'fin_des'
        },
        {
            observation: 'discChoice1a',
            type: 'fin_choice',
            help: false,
            meta: {
                value_1: "1000",
                value_2: "2000",
                prefix: "USD",
                generate: {
                    delay: 14999,
                    prev_ob: "",
                    delta: 0
                },
            },
            auto_advance: true,
            timer: {
                time: 15000,
                extend: false,
                pause: false,
                visible: true
            },
            next: {
                visible: false,
                enabled: true,
                rules: {
                    visible: true,
                    enabled: [
                        "observation"
                    ]
                }
            },
            module: 'fin_des'
        },
        {
            observation: 'discChoice2a',
            type: 'fin_choice',
            help: false,
            meta: {
                value_1: "1000",
                value_2: "2000",
                prefix: "USD",
                generate: {
                    delay: 14999,
                    prev_ob: "discChoice1a",
                    delta: 400
                },
            },
            auto_advance: true,
            timer: {
                time: 15000,
                extend: false,
                pause: false,
                visible: true
            },
            next: {
                visible: false,
                enabled: true,
                rules: {
                    visible: true,
                    enabled: [
                        "observation"
                    ]
                }
            },
            module: 'fin_des'
        },
        {
            observation: 'discChoice3a',
            type: 'fin_choice',
            help: false,
            meta: {
                value_1: "1000",
                value_2: "2000",
                prefix: "USD",
                generate: {
                    delay: 14999,
                    prev_ob: "discChoice2a",
                    delta: 200
                }
            },
            auto_advance: true,
            timer: {
                time: 15000,
                extend: false,
                pause: false,
                visible: true
            },
            next: {
                visible: false,
                enabled: true,
                rules: {
                    visible: true,
                    enabled: [
                        "observation"
                    ]
                }
            },
            module: 'fin_des'
        },
        {
            observation: 'discChoice4a',
            type: 'fin_choice',
            help: false,
            meta: {
                value_1: "1000",
                value_2: "2000",
                prefix: "USD",
                generate: {
                    delay: 14999,
                    prev_ob: "discChoice3a",
                    delta: 100
                },
            },
            auto_advance: true,
            timer: {
                time: 15000,
                extend: false,
                pause: false,
                visible: true
            },
            next: {
                visible: false,
                enabled: true,
                rules: {
                    visible: true,
                    enabled: [
                        "observation"
                    ]
                }
            },
            module: 'fin_des'
        },
        {
            observation: 'discChoice5a',
            type: 'fin_choice',
            help: false,
            meta: {
                value_1: "1000",
                value_2: "2000",
                prefix: "USD",
                generate: {
                    delay: 14999,
                    prev_ob: "discChoice4a",
                    delta: 50
                },
            },
            auto_advance: true,
            timer: {
                time: 15000,
                extend: false,
                pause: false,
                visible: true
            },
            next: {
                visible: false,
                enabled: true,
                rules: {
                    visible: true,
                    enabled: [
                        "observation"
                    ]
                }
            },
            module: 'fin_des'
        },
        {
            observation: 'discChoice6a',
            type: 'fin_choice',
            help: false,
            meta: {
                value_1: "1000",
                value_2: "2000",
                prefix: "USD",
                generate: {
                    delay: 14999,
                    prev_ob: "discChoice5a",
                    delta: 20
                },
            },
            auto_advance: true,
            timer: {
                time: 15000,
                extend: false,
                pause: false,
                visible: true
            },
            next: {
                visible: false,
                enabled: true,
                rules: {
                    visible: true,
                    enabled: [
                        "observation"
                    ]
                }
            },
            module: 'fin_des'
        },
        {
            observation: 'discChoice7a',
            type: 'fin_choice',
            help: false,
            meta: {
                value_1: "1000",
                value_2: "2000",
                prefix: "USD",
                generate: {
                    delay: 14999,
                    prev_ob: "discChoice6a",
                    delta: -50
                }
            },
            auto_advance: true,
            timer: {
                time: 15000,
                extend: false,
                pause: false,
                visible: true
            },
            next: {
                visible: false,
                enabled: true,
                rules: {
                    visible: true,
                    enabled: [
                        "observation"
                    ]
                }
            },
            module: 'fin_des'
        },
        {
            observation: 'disc_framing2',
            type: 'multiple_instructions',
            help: false,
            meta: {
                instruction: {
                    alignment: 'left'
                },
                image: {
                    width: 80,
                    source: 'disc_framing2'
                }
            },
            auto_advance: true,
            timer: {
                time: 5000,
                extend: false,
                pause: false,
                visible: false
            },
            next: {
                visible: true,
                enabled: true
            },
            module: 'fin_des'
        },

        {
            observation: 'discChoice1b',
            type: 'fin_choice',
            help: false,
            meta: {
                value_1: "1000",
                value_2: "2000",
                prefix: "USD",
                generate: {
                    delay: 14999,
                    prev_ob: "",
                    delta: 0
                },
            },
            auto_advance: true,
            timer: {
                time: 15000,
                extend: false,
                pause: false,
                visible: true
            },
            next: {
                visible: false,
                enabled: true,
                rules: {
                    visible: true,
                    enabled: [
                        "observation"
                    ]
                }
            },
            module: 'fin_des'
        },
        {
            observation: 'discChoice2b',
            type: 'fin_choice',
            help: false,
            meta: {
                value_1: "1000",
                value_2: "2000",
                prefix: "USD",
                generate: {
                    delay: 14999,
                    prev_ob: "discChoice1b",
                    delta: 400
                }
            },
            auto_advance: true,
            timer: {
                time: 15000,
                extend: false,
                pause: false,
                visible: true
            },
            next: {
                visible: false,
                enabled: true,
                rules: {
                    visible: true,
                    enabled: [
                        "observation"
                    ]
                }
            },
            module: 'fin_des'
        },
        {
            observation: 'discChoice3b',
            type: 'fin_choice',
            help: false,
            meta: {
                value_1: "1000",
                value_2: "2000",
                prefix: "USD",
                generate: {
                    delay: 14999,
                    prev_ob: "discChoice2b",
                    delta: 200
                },
            },
            auto_advance: true,
            timer: {
                time: 15000,
                extend: false,
                pause: false,
                visible: true
            },
            next: {
                visible: false,
                enabled: true,
                rules: {
                    visible: true,
                    enabled: [
                        "observation"
                    ]
                }
            },
            module: 'fin_des'
        },
        {
            observation: 'discChoice4b',
            type: 'fin_choice',
            help: false,
            meta: {
                value_1: "1000",
                value_2: "2000",
                prefix: "USD",
                generate: {
                    delay: 14999,
                    prev_ob: "discChoice3b",
                    delta: 100
                },
            },
            auto_advance: true,
            timer: {
                time: 15000,
                extend: false,
                pause: false,
                visible: true
            },
            next: {
                visible: false,
                enabled: true,
                rules: {
                    visible: true,
                    enabled: [
                        "observation"
                    ]
                }
            },
            module: 'fin_des'
        },
        {
            observation: 'discChoice5b',
            type: 'fin_choice',
            help: false,
            meta: {
                value_1: "1000",
                value_2: "2000",
                prefix: "USD",
                generate: {
                    delay: 14999,
                    prev_ob: "discChoice4b",
                    delta: 50
                },
            },
            auto_advance: true,
            timer: {
                time: 15000,
                extend: false,
                pause: false,
                visible: true
            },
            next: {
                visible: false,
                enabled: true,
                rules: {
                    visible: true,
                    enabled: [
                        "observation"
                    ]
                }
            },
            module: 'fin_des'
        },
        {
            observation: 'discChoice6b',
            type: 'fin_choice',
            help: false,
            meta: {
                value_1: "1000",
                value_2: "2000",
                prefix: "USD",
                generate: {
                    delay: 14999,
                    prev_ob: "discChoice5b",
                    delta: 20
                },
            },
            auto_advance: true,
            timer: {
                time: 15000,
                extend: false,
                pause: false,
                visible: true
            },
            next: {
                visible: false,
                enabled: true,
                rules: {
                    visible: true,
                    enabled: [
                        "observation"
                    ]
                }
            },
            module: 'fin_des'
        },
        {
            observation: 'discChoice7b',
            type: 'fin_choice',
            help: false,
            meta: {
                value_1: "1000",
                value_2: "2000",
                prefix: "USD",
                generate: {
                    delay: 14999,
                    prev_ob: "discChoice6b",
                    delta: -50
                },
            },
            auto_advance: true,
            timer: {
                time: 15000,
                extend: false,
                pause: false,
                visible: true
            },
            next: {
                visible: false,
                enabled: true,
                rules: {
                    visible: true,
                    enabled: [
                        "observation"
                    ]
                }
            },
            module: 'fin_des'
        },
        {
            observation: 'disc_framing3',
            type: 'multiple_instructions',
            help: false,
            meta: {
                instruction: {
                    alignment: 'left'
                },
                image: {
                    width: 80,
                    source: 'disc_framing3'
                }
            },
            auto_advance: true,
            timer: {
                time: 5000,
                extend: false,
                pause: false,
                visible: false
            },
            next: {
                visible: true,
                enabled: true
            },
            module: 'fin_des'
        },

        {
            observation: 'discChoice1c',
            type: 'fin_choice',
            help: false,
            meta: {
                value_1: "1000",
                value_2: "2000",
                prefix: "USD",
                generate: {
                    delay: 14999,
                    prev_ob: "",
                    delta: 0
                }
            },
            auto_advance: true,
            timer: {
                time: 15000,
                extend: false,
                pause: false,
                visible: true
            },
            next: {
                visible: false,
                enabled: true,
                rules: {
                    visible: true,
                    enabled: [
                        "observation"
                    ]
                }
            },
            module: 'fin_des'
        },
        {
            observation: 'discChoice2c',
            type: 'fin_choice',
            help: false,
            meta: {
                value_1: "1000",
                value_2: "2000",
                prefix: "USD",
                generate: {
                    delay: 14999,
                    prev_ob: "discChoice1c",
                    delta: 400
                }
            },
            auto_advance: true,
            timer: {
                time: 15000,
                extend: false,
                pause: false,
                visible: true
            },
            next: {
                visible: false,
                enabled: true,
                rules: {
                    visible: true,
                    enabled: [
                        "observation"
                    ]
                }
            },
            module: 'fin_des'
        },
        {
            observation: 'discChoice3c',
            type: 'fin_choice',
            help: false,
            meta: {
                value_1: "1000",
                value_2: "2000",
                prefix: "USD",
                generate: {
                    delay: 14999,
                    prev_ob: "discChoice2c",
                    delta: 200
                }
            },
            auto_advance: true,
            timer: {
                time: 15000,
                extend: false,
                pause: false,
                visible: true
            },
            next: {
                visible: false,
                enabled: true,
                rules: {
                    visible: true,
                    enabled: [
                        "observation"
                    ]
                }
            },
            module: 'fin_des'
        },
        {
            observation: 'discChoice4c',
            type: 'fin_choice',
            help: false,
            meta: {
                value_1: "1000",
                value_2: "2000",
                prefix: "USD",
                generate: {
                    delay: 14999,
                    prev_ob: "discChoice3c",
                    delta: 100
                }
            },
            auto_advance: true,
            timer: {
                time: 15000,
                extend: false,
                pause: false,
                visible: true
            },
            next: {
                visible: false,
                enabled: true,
                rules: {
                    visible: true,
                    enabled: [
                        "observation"
                    ]
                }
            },
            module: 'fin_des'
        },
        {
            observation: 'discChoice5c',
            type: 'fin_choice',
            help: false,
            meta: {
                value_1: "1000",
                value_2: "2000",
                prefix: "USD",
                generate: {
                    delay: 14999,
                    prev_ob: "discChoice4c",
                    delta: 50
                }
            },
            auto_advance: true,
            timer: {
                time: 15000,
                extend: false,
                pause: false,
                visible: true
            },
            next: {
                visible: false,
                enabled: true,
                rules: {
                    visible: true,
                    enabled: [
                        "observation"
                    ]
                }
            },
            module: 'fin_des'
        },
        {
            observation: 'discChoice6c',
            type: 'fin_choice',
            help: false,
            meta: {
                value_1: "1000",
                value_2: "2000",
                prefix: "USD",
                generate: {
                    delay: 14999,
                    prev_ob: "discChoice5c",
                    delta: 20
                }
            },
            auto_advance: true,
            timer: {
                time: 15000,
                extend: false,
                pause: false,
                visible: true
            },
            next: {
                visible: false,
                enabled: true,
                rules: {
                    visible: true,
                    enabled: [
                        "observation"
                    ]
                }
            },
            module: 'fin_des'
        },
        {
            observation: 'discChoice7c',
            type: 'fin_choice',
            help: false,
            meta: {
                value_1: "1000",
                value_2: "2000",
                prefix: "USD",
                generate: {
                    delay: 14999,
                    prev_ob: "discChoice6c",
                    delta: -50
                }
            },
            auto_advance: true,
            timer: {
                time: 15000,
                extend: false,
                pause: false,
                visible: true
            },
            next: {
                visible: false,
                enabled: true,
                rules: {
                    visible: true,
                    enabled: [
                        "observation"
                    ]
                }
            },
            module: 'fin_des'
        }
    ]
};
