import React, { Fragment, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { GlobalBaseStyle, CenterContent } from '../../assets/styles/BaseStyle';

import { Button, ListStyle } from './TransitionStyle';
import { extract_text } from '../../helpers';

import { LocaleContext } from '../../context/LocalContext';

import _get from 'lodash.get';
import { loadImage } from '../../helpers/image_loader';
import { useDynamicContent } from '../../hooks';

const TransitionStart = ({ module, subtitle_2, subtitle_1, title, observation, handleNavigation, v2, button_text, showUid, uId}) => {
  const translation = useContext(LocaleContext);
  const contents = useDynamicContent(module, observation)
  useEffect(() => getStartTime());

  const setLocalValue = () => {
    getStartTime();
    handleNavigation(1);
  }

  const getStartTime = () => {
    let date = moment.utc().format('YYYY-MM-DD HH:mm:ss');
    let stillUtc = moment.utc(date).toDate();
    let local = moment(stillUtc).local().format('hh:mm A');
    localStorage.setItem('startDate', local);
  }

  return (
    <Fragment>
      <GlobalBaseStyle />
      <ListStyle />
      <CenterContent center={true} >
        <div style={{
          marginLeft: '10px',
          marginRight: '10px',
          textAlign: 'center',
          justifyContent: 'center'
        }}>
          <div style={{ justifyContent: 'center', marginTop: '20px' }} className="row">
            <img src={loadImage('group-of-people')} className="center" />
          </div>
          <div className="row" >
            <div className={`col-xs-12 offset-md-12 col-md-12`} style={{ textAlign: 'center' }}>
              <div className={`lefl-module-title moduleTitle`}> {contents?.title || extract_text(title) ||  _get(translation, `${observation}.title`)} </div>
            </div>
          </div>
          <div  className="row">
            <div className={`lefl-module-sub-title moduleSubTitle`}> {contents?.subtitle_1|| extract_text(subtitle_1) ||  _get(translation, `${observation}.subtitle_1`)} </div>
          </div>
          <div  hidden={!showUid} className={`lefl-module-sub-title moduleSubTitle center`}> {contents?.subtitle_2 || extract_text(subtitle_2) ||  _get(translation, `${observation}.subtitle_2`)}{uId} </div>

          <div style={{ justifyContent: 'center' }} className="row">
            {
              v2 && <Button onClick={() => setLocalValue()}>
                <div>{contents?.button_text || extract_text(button_text) || _get(translation, `${observation}.button_text`)}</div>
              </Button>
            }
          </div>
        </div>
      </CenterContent>
    </Fragment>
  )
};

TransitionStart.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  sub_title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  handleNavigation: PropTypes.func,
  module: PropTypes.string,
  subtitle_2: PropTypes.string,
  subtitle_1: PropTypes.string,
  observation: PropTypes.string,
  v2: PropTypes.bool,
  button_text: PropTypes.string,
  showUid: PropTypes.bool,
  uId: PropTypes.string,
};

export default TransitionStart;