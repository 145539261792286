
import _get from 'lodash.get';

export const extract_text = (texts) => {
  const text_type = typeof texts;

  if (text_type === 'string' || text_type === 'number') return texts;
  if (text_type === 'object') {
    if (texts.text) {
      return texts.text
    } else {
      return ''
    }
  }
  if (text_type === 'boolean') {
    return !texts
  }
};

export const parse_translation = ({ translation, observation, state }) => {
  const target_translation = _get(translation, `${observation}`, {});
  const parsed = { ...target_translation };

  Object.keys(state).forEach(function (st) {
    Object.keys(parsed).forEach(function (translation) {
      const str = `{{{${st}}}}`;

      parsed[translation] = parsed[translation].replace(new RegExp(str, 'g'), state[st])
    })
  });

  return {
    ...translation,
    [observation]: parsed
  };
};

export const parse_image = (image, state = {}) => {
  const mapper = {
    gender: {
      1: 'male',
      2: 'female',
      3: "non-binary",
      default: 'male'
    }
  };

  let result = image;

  Object.keys(mapper).forEach(function (map) {
    result = image.replace(`{{{${map}}}}`, _get(mapper, `${map}.default`));

    Object.keys(state).forEach(function (st) {
      const str = `{{{${map}}}}`;
      result = image.replace(str, _get(mapper, `${map}.${state[st]}`, _get(mapper, `${map}.default`)))
    });
  });

  return result
};
export const getRandomIntInclusive = (min, max) => {
  const randomBuffer = new Uint32Array(1);
  window.crypto.getRandomValues(randomBuffer);
  let randomNumber = randomBuffer[0] / (0xffffffff + 1);
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(randomNumber * (max - min + 1)) + min;
}
export const getText = ({text, contents, translation, observation ,key, defaultTxt}) => {
  return _get(contents, key)|| extract_text(text) || _get(translation, `${observation}.${key}`,defaultTxt)
}