import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import { Container } from '@material-ui/core'
import Header from './components/Header'
import IdIntegration from './pages/Init/IdIntegration'
import React, { useEffect, useState } from 'react'
import TokenIntegration from './pages/Init/TokenIntegration'
import { AssessmentCancelled, AssessmentExpired, Expired, NotFound, AlreadyTaken, Blocked, ThankYouPage } from './pages/index'
import { useSelector } from 'react-redux'


const RoutingPlayer = () => {
  const { activeScreen, modules } = useSelector(st => st.module);
  const [hideLogoInMobile, setHideLogoInMobile] = useState(true);
  useEffect(() => {
    if (activeScreen && modules?.props?.screens) {
      const screenProps = modules.props.screens.find(sc => sc.observation === activeScreen)
      if (screenProps) {
        setHideLogoInMobile(!!screenProps.mobileLogoVisible)
      }
    } else {
      setHideLogoInMobile(true)
    }

  }, [activeScreen, modules])
  return (
    <>
      <Router>
        <Header mobileLogoVisible={hideLogoInMobile} />
        <Container maxWidth='lg'>
          <Switch>
            <Route exact path='/unauthorized' component={NotFound} />
            <Route exact path='/blocked' component={Blocked} />
            <Route exact path='/already-taken' component={AlreadyTaken} />
            <Route exact path='/cancelled' component={AssessmentCancelled} />
            <Route exact path='/thank-you' component={ThankYouPage} />
            <Route exact path='/session-expired' component={Expired} />
            <Route exact path='/assessment-expired' component={AssessmentExpired} />
            <Route exact path='/ids' component={IdIntegration} />
            <Route exact path='/:token' component={TokenIntegration} />
            <Route path='*' component={NotFound} />
          </Switch>
        </Container>
      </Router>
    </>
  )
}

export default RoutingPlayer
