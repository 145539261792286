import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import { DataService } from "../../config/dataService/dataService";
import LoadingScreen from "../../components/LoadingScreen";

function IdIntegration(props) {
  const { loading } = useSelector(State => State.auth);
  const query = new URLSearchParams(props.location.search);
  const uid = query.get('uid');
  const integrationId = query.get('integrationId')

  const history = useHistory()

  useEffect(() => {
    if (!uid || !integrationId) {
      history.push('/unauthorized')
    }
    getIntegrationDetails()
  }, []);
  const getIntegrationDetails = async () => {
    getToken()
  }

  const getToken = async () => {
    let data = await DataService.call({
      url: process.env.REACT_APP_SESSION_SERVECE + 'tokens',
      method: "POST",
      data: { uid, integration_id: integrationId }
    }).catch(err => { console.log(err); history.push('/unauthorized') })
    if (data) {
      history.push('/' + data.access_token)
    }
  }

  return (
    <div>
      {loading ? <LoadingScreen /> : ''}
    </div>
  );
}

export default IdIntegration;
