class BaseError extends Error {
    constructor(httpCode, message = null, errors = null, isOperational = true, errorCode = undefined) {
        super(message);
        this.name = this.constructor.name
        this.message = message
        this.code = httpCode;
        Error.captureStackTrace(this);
    }
}
export class ForbiddenError extends BaseError {
    constructor() {
        super(403, 'Forbidden');
    }
}