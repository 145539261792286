const actions = {
  SET_MODULE_LIST: "SET_MODULE_LIST",
  SET_MODULES: "SET_MODULES",
  SET_ACTIVE_INDEX: "SET_ACTIVE_INDEX",
  SET_CURRENT_MODULE: "SET_CURRENT_MODULE",
  SET_CURRENT_SCREEN: "SET_CURRENT_SCREEN",
  SET_COMPLETED: "SET_COMPLETED",
  SET_ACTIVE_SCREEN: "SET_ACTIVE_SCREEN",
  SET_PROGRESS_BAR: "SET_PROGRESS_BAR",
  SET_PROGRESS_BAR_NAVIGATE: "SET_PROGRESS_BAR_NAVIGATE",

  END_SESSION_API_BEGIN: "END_SESSION_API_BEGIN",
  END_SESSION_API_FAIL: "END_SESSION_API_FAIL",
  END_SESSION_API_SUCCESS: "END_SESSION_API_SUCCESS",
  SET_HEADER: "SET_HEADER",

  progressBarNavigateAction: data => {
    return {
      type: actions.SET_PROGRESS_BAR_NAVIGATE
    };
  },
  setProgressBarAction: data => {
    return {
      type: actions.SET_PROGRESS_BAR,
      data,
    };
  },

  setModuleListAction: data => {
    return {
      type: actions.SET_MODULE_LIST,
      data,
    };
  },

  setCurrentModuleAction: data => {
    return {
      type: actions.SET_CURRENT_MODULE,
      data,
    };
  },

  setCurrentScreenAction: data => {
    return {
      type: actions.SET_CURRENT_SCREEN,
      data,
    };
  },
  setActiveScreenAction: data => {
    return {
      type: actions.SET_ACTIVE_SCREEN,
      data,
    };
  },

  setModulesAction: data => {
    return {
      type: actions.SET_MODULES,
      data,
    };
  },

  setActiveIndexAction: data => {
    return {
      type: actions.SET_ACTIVE_INDEX,
      data,
    };
  },

  setCompletedAction: data => {
    return {
      type: actions.SET_COMPLETED,
      data,
    };
  },

  endSessionAPiCallBegin: data => {
    return {
      type: actions.END_SESSION_API_BEGIN,
      data,
    };
  },
  endSessionAPiCallFail: data => {
    return {
      type: actions.END_SESSION_API_FAIL,
      data,
    };
  },
  endSessionAPiCallSuccess: data => {
    return {
      type: actions.END_SESSION_API_SUCCESS,
      data,
    };
  },
  setHeaderAction: data => {
    return {
      type: actions.SET_HEADER,
      data,
    };
  },
};

export default actions;