import styled from 'styled-components';
import { RaisedButton } from '../../assets/styles/BaseStyle';

export const ImageButton = styled.div`
  display: flex;
	flex-wrap: wrap;
  width: '100%';
	justify-content: center;
  margin-bottom:20px;
  button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 26%;
    margin: 15px 30px;
    padding: 10px;
    overflow: hidden;
    border: 0px solid #BD1E6F;
    background-color: #fff;
    &.selected{
      border: 2px solid #BD1E6F;
    }
    img {
      max-width: 100%;
      margin-bottom: 10px;
      
    }
    label {
      display: block;
    }

    @media(min-width:992px)and (max-width:1275px){
      width: 27%;
      margin: 15px 18px;
    }
    @media(min-width:768px)and (max-width:991px){
      width: 26%;
      margin: 15px 16px;
    }
    @media(min-width:576px)and (max-width:767px){
      width: 26%;
      margin: 12px;
    }

    @media(min-width:320px)and (max-width:575px){
      width: 26%;
      margin: 15px 10px;
    }

    &.help-div{
      margin: 15px;
        cursor: auto !important;
        &:active,
        &.active{
          box-shadow: 0 0px 6px rgba(0, 0, 0, 0.3), 0 6px 12px rgba(0, 0, 0, 0.4);
        }
      
      @media(min-width:992px)and (max-width:1275px){
        width: 27%;
        margin: 12px;
      }
      @media(min-width:768px)and (max-width:991px){
        width: 26%;
        margin: 4px;
      }
      @media(min-width:576px)and (max-width:767px){
        width: 26%;
        margin: 12px;
      }
  
      @media(min-width:320px)and (max-width:575px){
        width: 26%;
        margin: 15px 10px;
      }
    }
  }
`;

export const Item = styled(RaisedButton)`
  

  

`;