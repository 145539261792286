import Styled from 'styled-components';

const Main = Styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        min-height: 100vh;
        text-align: center;
        h1{
            font-size:1.5rem;
            margin-bottom:3rem;
            font-weight:400;
        }
        h4{
            font-size:1.125rem;
            font-weight:unset;
            margin-bottom:1.4rem;
            max-width:600px;
            line-height: 1.6;
        }
        button{
            height:44px;
            font-size:1.125rem;
            font-weight:300;
            line-height: 1.5715;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            padding: 0px 32px;
            background: #fff;
            border-width: 1px;
            border-style: solid;
        }
`
export { Main }