
import React, { useContext } from 'react';

import { ServeyCancelContainer, ServeyCancel,ServeyCancelDesc } from './ServeyCancelComponentStyles';
import { CenterContent, GlobalBaseStyle } from '../../assets/styles/BaseStyle';
import { LocaleContext } from '../../context/LocalContext';
import { extract_text } from '../../helpers';
import _get from 'lodash.get';

const ServeyCancelComponent = ({ title, observation,desc }) => {
  const translation = useContext(LocaleContext);


  return <>
    <GlobalBaseStyle />
    <CenterContent center={true}>
      <ServeyCancelContainer>
        <ServeyCancel>{extract_text(title) || _get(translation, `${observation}.title`)}</ServeyCancel>
        <ServeyCancelDesc>{extract_text(desc) || _get(translation, `${observation}.desc`,'')}</ServeyCancelDesc>
      </ServeyCancelContainer>
    </CenterContent>
  </>
};

export default ServeyCancelComponent;
