import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './assets/css/bootstrap.min.css';
import './assets/fonts/efl-iconfont.woff';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

ReactDOM.render(
    <App />,
  document.getElementById('root')
);
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  environment: process.env.REACT_APP_ENV,
  tracesSampleRate: process.env.REACT_APP_SENTRY_SAMPLE_RATE,
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();
