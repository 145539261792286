import _get from 'lodash.get'
import React, {Fragment, useContext, useEffect, useState} from 'react'
import {ThemeProvider} from 'styled-components'
import {CenterContent, GlobalBaseStyle, Question} from '../../assets/styles/BaseStyle'
import {LocaleContext} from '../../context/LocalContext'
import setTheme from '../../context/ThemeProvider'
import {extract_text} from '../../helpers'
import {loadImage} from '../../helpers/image_loader'
import useThemeConfig from '../../hooks/useThemeConfig'
import {
  ActionButton,
  Coin,
  CoinWrapper,
  Container,
  ContentSub,
  Field,
  FieldWrapper,
  NextBtn,
  NextButton,
  PopModalBody,
  PopUp,
  TextWrapper,
} from './CoinDecisionStyle'

let currentTime = Date.now()
let i = 0

const CoinDecision = ({
  handleNavigation,
  title,
  theme,
  coinImg,
  totleCoins = [],
  onChangeHandler,
  observation,
  fields = [],
  coinSpend
}) => {
  const translation = useContext(LocaleContext)
  const themeConfig = useThemeConfig()
  const [coins, setCoin] = useState(totleCoins)
  const [next, setNext] = useState(false)
  const [flag, setFlag] = useState(false)
  const [count, setCount] = useState(0)
  const [coinData, setCoinData] = useState({})
  const [clickSequence, setClickSequence] = useState([])
  const [timeSequence, setTimeSequence] = useState([])
  const [totalScore, setTotalScore] = useState(0)
  useEffect(() => {
    if (typeof onChangeHandler === 'function' || flag) {
      setFlag(true)
      onChangeHandler({
        coins,
        observation,
        totalTime: Date.now() - currentTime,
        clickSequence,
        timeSequence,
        itsDone: coins.length === count ? true : false,
        coinSpend: Object.fromEntries(
          coinSpend.map(cs => [cs[0], cs[1]])
        ),
        totleCoins:totleCoins.length,
        totalScore:totalScore
      })
    }
  }, [count])

  const minusCoin = (field, i) => {
    changeCoins(false)
    const time = Date.now() - currentTime
    setClickSequence([...clickSequence, -Math.abs(i + 1)])
    setTimeSequence([...timeSequence, time])
    coinSpend[i][1]--;
    setTotalScore(totalScore+1)
    setCoinData(() => {
      return {
        ...coinData,
        [field]: {count: coinData[field].count - 1, time},
      }
    })
  }

  const plusCoin = (field, i) => {
    changeCoins(true)
    const time = Date.now() - currentTime
    setClickSequence([...clickSequence, i + 1])
    setTimeSequence([...timeSequence, time])
    coinSpend[i][1]++;
    if (count < coins.length)
      setCoinData(() => {
        const data = coinData[field]
        if (data)
          return {
            ...coinData,
            [field]: {count: coinData[field]?.count + 1, time},
          }
        else return {...coinData, [field]: {count: 1, time}}
      })
  }
  const nextFuction = () => {
    if (coins.length != count) setNext(true)
    else handleNavigation(1)
  }
  const changeCoins = changeLog => {
    const data = [false, null]
    coins.find((bool, i) => {
      if (!data[0] && bool === false) {
        data[0] = true
        data[1] = i
      }
    })
    setCoin(() => {
      setCount(changeLog ? count + 1 : count - 1)
      if (changeLog) {
        if (!data[0]) {
          coins[coins.length - 1] = false
          return [...coins]
        } else {
          coins[data[1] - 1] = false
          return [...coins]
        }
      } else {
        if (!data[0]) {
          coins[coins.length] = true
          return [...coins]
        } else {
          coins[data[1]] = true
          return [...coins]
        }
      }
    })
  }

  const Fiels = () => {
    return fields.map((field, i) => {
      const data = coinData[field]
      return (
        <Field key={i}>
          <p>{_get(translation, `${observation}.option${i}`)}</p>
          <Container value={data?.count}>
            <ActionButton disabled={data?.count ? false : true} onClick={() => minusCoin(field, i)}>
              <svg
                width='15'
                height='15'
                viewBox='0 0 10 3'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M9.3125 0.9375H0.6875C0.570312 0.9375 0.5 1.03125 0.5 1.125V1.875C0.5 1.99219 0.570312 2.0625 0.6875 2.0625H9.3125C9.40625 2.0625 9.5 1.99219 9.5 1.875V1.125C9.5 1.03125 9.40625 0.9375 9.3125 0.9375Z'
                  fill='white'
                />
              </svg>
            </ActionButton>
            <div className='counter'>{data ? data.count : 0}</div>
            <ActionButton
              disabled={count < coins.length ? false : true}
              onClick={() => plusCoin(field, i)}
            >
              <svg
                width='15'
                height='15'
                viewBox='0 0 10 9'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M9.3125 3.9375H5.5625V0.1875C5.5625 0.09375 5.46875 0 5.375 0H4.625C4.50781 0 4.4375 0.09375 4.4375 0.1875V3.9375H0.6875C0.570312 3.9375 0.5 4.03125 0.5 4.125V4.875C0.5 4.99219 0.570312 5.0625 0.6875 5.0625H4.4375V8.8125C4.4375 8.92969 4.50781 9 4.625 9H5.375C5.46875 9 5.5625 8.92969 5.5625 8.8125V5.0625H9.3125C9.40625 5.0625 9.5 4.99219 9.5 4.875V4.125C9.5 4.03125 9.40625 3.9375 9.3125 3.9375Z'
                  fill='white'
                />
              </svg>
            </ActionButton>
          </Container>
        </Field>
      )
    })
  }

  const Coins = () => {
    return (
      <CoinWrapper>
        {coins.map((coin, ind) => (
          <Coin value={coin} key={ind}>
            <img height={25} width={25} key={i} src={loadImage(coinImg[coin ? 0 : 1])} alt='coin' />
          </Coin>
        ))}
      </CoinWrapper>
    )
  }

  return (
    <Fragment>
      <PopUp isOpen={next}>
        <PopModalBody>
          <div className={`title`}>{_get(translation, `${observation}.subtitle`)}</div>
          <ContentSub>{_get(translation, `${observation}.subtitle1`)}</ContentSub>
          <NextButton onClick={() => handleNavigation(1)}>
            <div>{_get(translation, `${observation}.btntitle1`)}</div>
          </NextButton>
          <NextButton onClick={() => setNext(false)}>
            <div>{_get(translation, `${observation}.btntitle2`)}</div>
          </NextButton>
        </PopModalBody>
      </PopUp>
      <ThemeProvider theme={setTheme(theme || themeConfig)}>
        <GlobalBaseStyle />
        <TextWrapper>
          <Question alignment={title.alignment}>
            {extract_text(title) || _get(translation, `${observation}.title`)}
          </Question>
        </TextWrapper>
        <Coins />
        <FieldWrapper>
          <Fiels />
        </FieldWrapper>
        <CenterContent center={true}>
          <NextBtn>
            <NextButton onClick={() => nextFuction()}>
              {_get(translation, `${observation}.next`)}
            </NextButton>
          </NextBtn>
        </CenterContent>
      </ThemeProvider>
    </Fragment>
  )
}

export default CoinDecision
