import actions from './actions';

const {
  SET_MODULE_LIST,
  SET_MODULES,
  SET_ACTIVE_INDEX,
  SET_CURRENT_MODULE,
  SET_CURRENT_SCREEN,
  SET_COMPLETED,
  SET_ACTIVE_SCREEN,
  SET_PROGRESS_BAR,
  SET_PROGRESS_BAR_NAVIGATE,
  END_SESSION_API_BEGIN, END_SESSION_API_FAIL, END_SESSION_API_SUCCESS,
  SET_HEADER
} = actions;

const initState = {
  moduleList: [],
  modules: [],
  activeIndex: 0,
  module: null,
  screen: null,
  completed: false,
  activeScreen: null,
  progressbar: {},
  header: true,
  endSessionApi: {
    loading: false,
    error: null,
    data: null
  }
};

const ModuleReducer = (state = initState, action = {}) => {
  const { type, data } = action;
  switch (type) {
    case SET_MODULE_LIST:
      return {
        ...state,
        moduleList: data,
        modules: data[initState.activeIndex]
      };
    case SET_MODULES:
      return {
        ...state,
        modules: data,
      };
    case SET_ACTIVE_INDEX:
      return {
        ...state,
        activeIndex: data,
      };
    case SET_CURRENT_MODULE:
      return {
        ...state,
        module: data,
      };
    case SET_CURRENT_SCREEN:
      return {
        ...state,
        screen: data,
      };
    case SET_ACTIVE_SCREEN:
      return {
        ...state,
        activeScreen: data,
      };
    case SET_PROGRESS_BAR:
      return {
        ...state,
        progressbar: data,
      };
    case SET_PROGRESS_BAR_NAVIGATE:
      let { total, completed } = state.progressbar
      return {
        ...state,
        progressbar: { total, completed: completed + 1 },
      };
    case SET_COMPLETED:
      return {
        ...state,
        completed: data,
        endSessionApi: {
          loading: true,
          error: null
        }
      };
    case END_SESSION_API_BEGIN:
      return {
        ...state,
        header: false,
        endSessionApi: {
          loading: true,
          error: null

        }
      };
    case END_SESSION_API_SUCCESS:
      return {
        ...state,
        endSessionApi: {
          loading: false,
          data: data || true
        }
      };
    case END_SESSION_API_FAIL:
      return {
        ...state,
        endSessionApi: {
          loading: false,
          data: data
        }
      };
    case SET_HEADER:
      return {
        ...state,
        header: data
      };
    default:
      return state;
  }
};
export default ModuleReducer;
