import { DataService } from '../../config/dataService/dataService';
import { setSurveyCompleted } from '../authentication/actionCreator';
import actions from './actions';
const {
  setModuleListAction,
  setCurrentModuleAction,
  setCurrentScreenAction,
  setModulesAction,
  setActiveIndexAction,
  setCompletedAction,
  setActiveScreenAction,
  setProgressBarAction,
  progressBarNavigateAction,
  endSessionAPiCallBegin,
  endSessionAPiCallFail,
  endSessionAPiCallSuccess,
  setHeaderAction
} = actions;

const progressBarNavigate = () => {
  return async dispatch => {
    dispatch(progressBarNavigateAction());
  }
};
const setProgressBar = ({ total, completed }) => {
  const data = {
    ...total !== undefined && { total },
    ...completed !== undefined && { completed }
  }
  return async dispatch => {
    dispatch(setProgressBarAction(data));
  }
};
const setModuleList = (data) => {
  return async dispatch => {
    dispatch(setModuleListAction(data))
  };
};

const setCurrentModule = (data) => {
  return async dispatch => {
    dispatch(setCurrentModuleAction(data))
  };
};

const setCurrentScreen = (data) => {
  return async dispatch => {
    dispatch(setCurrentScreenAction(data))
  };
};
const setActiveScreenStore = (data) => {
  return async dispatch => {
    dispatch(setActiveScreenAction(data))
  };
};

const setModules = (data) => {
  return async dispatch => {
    dispatch(setModulesAction(data))
  };
};

const setActiveIndex = (data) => {
  return async dispatch => {
    dispatch(setActiveIndexAction(data))
  };
};

const setCompleted = (data) => {
  return async dispatch => {
    dispatch(setCompletedAction(data))
  };
};
const setHeader = (data) => {
  return async dispatch => {
    dispatch(setHeaderAction(data))
  };
};

const endSessionAPiCall = (body) => {
  return async dispatch => {
    try {
      dispatch(endSessionAPiCallBegin())
      const data = await DataService.post("psychometrics/end-session", body)
      dispatch(endSessionAPiCallSuccess(data))
      dispatch(setSurveyCompleted(true))
    } catch (err) {
      dispatch(endSessionAPiCallFail());
    }
  }
}


export {
  setModuleList,
  setCurrentModule,
  setCurrentScreen,
  setModules,
  setActiveIndex,
  setCompleted,
  setActiveScreenStore,
  setProgressBar,
  progressBarNavigate,
  endSessionAPiCall,
  setHeader
};
