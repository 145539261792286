import React from "react";
import { IntlProvider, FormattedMessage} from "react-intl";
import useLanguage from "../../hooks/useLanguage";
import { Main } from './style';

function AssessmentExpired() {
    const { messages, locale, setLocale } = useLanguage()

    return messages ? (
        <IntlProvider locale={locale} messages={messages}>
            <App locale={locale} direction="ltr" onLocaleChange={(locales) => setLocale(locales)} />
        </IntlProvider>
    ) : null;
}
export default AssessmentExpired;

function App({ _locale, _direction, _onLocaleChange }) {
    return (
        <Main>
            <h1 >
                <FormattedMessage id="assessmentExpired" />
            </h1>
            <h4 >
                <FormattedMessage id="assessmentExpiredSub1" />
            </h4>
            <h4>
                <FormattedMessage id="assessmentExpiredSub2" />
            </h4>
        </Main>
    );
}
