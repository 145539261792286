
import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';

import { GlobalBaseStyle } from '../../assets/styles/BaseStyle';

import { LeftPane, RightPane, Button } from './IntroStyle';
import { extract_text } from '../../helpers';

import { LocaleContext } from '../../context/LocalContext';
import { loadImage } from '../../helpers/image_loader';

import _get from 'lodash.get';

import FireImage from "../../components/FireImage/index"
const Intro = ({ locale,sub_text, title, agentImg = false, sub_title, observation, image, handleNavigation, v2,button_text }) => {
  const translation = useContext(LocaleContext);
  return (
    <Fragment>
      <GlobalBaseStyle />
      <div className="row">
        <div className="col-xs-12 offset-md-6 col-md-6">
          <div className={`lefl-module-title moduleTitle`}> {extract_text(title) || _get(translation, `${observation}.title`)} </div>
        </div>
      </div>
      <div style={{ justifyContent: 'center' }} className="row">
        <LeftPane className={`col-xs-12 col-md-6`}>
        {image.includes('fire_build_game/') ? <FireImage locale={locale} image ={image} text={_get(translation, `${observation}`)} type={"intro"}/>:
         <img src={loadImage(image)} alt="intro"/>}
        </LeftPane>
        <RightPane className={`col-xs-6 col-md-6`}>
          <div className={`lefl-module-sub-title moduleSubTitle`}> {extract_text(sub_title) || _get(translation, `${observation}.subtitle`)} </div>
          {sub_text && <div className={`lefl-module-sub-title`}> {extract_text(sub_title) || _get(translation, `${observation}.sub_text`)} </div>}
          {agentImg && <img src={loadImage('agent')}  alt="agent" />}
        </RightPane>
        {
          v2 && <Button onClick={() => handleNavigation(1)}>
            <div>{ extract_text(button_text) || _get(translation, `${observation}.button_text`)} </div>
          </Button>
        }
      </div>
    </Fragment>
  )
};

Intro.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  sub_title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  image: PropTypes.string,
  locale: PropTypes.string,
  agentImg: PropTypes.bool,
  observation: PropTypes.string,
  v2: PropTypes.bool,
  button_text: PropTypes.string,
  sub_text: PropTypes.string,
  handleNavigation: PropTypes.func
};

export default Intro;