import React, { useEffect, useState } from "react";
import { endSessionAPiCall, progressBarNavigate, setActiveIndex, setCompleted, setHeader, setModules } from '../../redux/module/actionCreator';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";

import { COOKIE, URL } from "../../helpers/constant";
import CookieHelper from "../../helpers/cookieHelper";
import { DataService } from "../../config/dataService/dataService";
import LoadingScreen from "../LoadingScreen";
import Player from "../Player/Player";
import { sessionExpired } from "../../lib/util";
import { setExpired, setSurveyCancelled } from '../../redux/authentication/actionCreator';
import JwtHelper from "../../helpers/jwtHelper";
import { getRandomIntInclusive } from "../../helpers";

const PlayerContainer = () => {

  const { client, callbacks, theme } = useSelector(State => State.config);
  const { loading, surveyCancelled } = useSelector(State => State.auth);
  const { activeIndex, moduleList, modules, screen, module, completed, endSessionApi } = useSelector(State => State.module);
  const [fpVariant] = useState(['a', 'b'][getRandomIntInclusive(0, 1)]);
  const [final, setFinal] = useState({});
  const user = CookieHelper.get(COOKIE.USER)
  const { token: jwtToken } = useParams()
  const decoded = new JwtHelper(jwtToken).decoded
  const dispatch = useDispatch();
  const history = useHistory();
  let locale = client.languageCode
  const [showPlayer, setShowPlayer] = useState(true);
  

  if (!['en', 'am', 'om', 'fr', 'es', 'th', 'ny', 'lg', 'rw','es-es'].includes(locale))
    locale = 'en'
  useEffect(() => {
    if (completed) {
      dispatch(progressBarNavigate())
      sendData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completed]);

  useEffect(() => {
    dispatch(setModules(moduleList[activeIndex]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex]);

  useEffect(() => {
    if (endSessionApi.loading) {
      setShowPlayer(false)
      dispatch(setHeader(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endSessionApi]);

  useEffect(() => {
    if (endSessionApi.data) {
      // endsession api call completed
      // show default page or redirect completed page
      if (client.showCompletionPage) {
        history.push('/thank-you')
      } else if (callbacks.successUrl) {
        window.location.href = callbacks.successUrl
        //alert("redirect")
      } else {
        history.push('/thank-you')
      }
    }
  }, [endSessionApi.data]);

  useEffect(() => {
    if (surveyCancelled) {
      if (!callbacks.failureUrl) {
        history.push(URL.FAILURE_URL)
      } else {
        window.location.href = callbacks.failureUrl
      }
    }
  }, [surveyCancelled]);

  const onModuleSubmit = async (observationsArray, moduleName, elapsedTime) => {
    try {
      elapsedTime = new Date().getTime() - elapsedTime
      await DataService.post("psychometrics/submit", {
        companyId: decoded.company_id,
        integrationId: user.integration_id,
        uid: user.uid,
        module: moduleName,
        observations: observationsArray,
        isLastScreen: true,
        elapsedTime
      })
    }
    catch (err) {
      if (err.code === 403) {
        dispatch(setExpired(true));
        sessionExpired()
        return
      }
    }
  }

  const onModuleFinish = async (_observations, elapsedTime, moduleName, status) => {
    if (!completed) {
      const obj = { [moduleName]: _observations };
      const y = { ...final, ...obj };
      setFinal(y);
      if ((moduleName === "finding_patterns" && _observations.rt_gamepref?.responseValue === "1")) {
        delete _observations.greatJob
        let a = moduleList[activeIndex];
        a.props.screens.splice(0, 1);
        moduleList.splice(moduleList.length - 1, 0, a);
      }
      let module_observation = _observations;
      let observationsArray = Object.keys(module_observation).map(function (k) {
        return { ...module_observation[k], ...{ key: k } };
      });
      
      if (activeIndex !== moduleList.length - 1) {
        onModuleSubmit(observationsArray, moduleName, elapsedTime).then(console.log).catch(console.log)
        dispatch(setActiveIndex(activeIndex + 1));
        // module finish - 
        if ((moduleName !== "finding_patterns" || _observations.rt_gamepref?.responseValue !== "1")) {
          dispatch(progressBarNavigate())
        }
      } else {
        await onModuleSubmit(observationsArray, moduleName, elapsedTime).then(console.log).catch(console.log)
        dispatch(setCompleted(true));
      }
    }
  };

  const onScreenSubmit = async (_observations, elapsedTime, moduleName, isLastScreen) => {
    if (!completed) {
      const obj = { [moduleName]: _observations };
      const y = { ...final, ...obj };
      if (
        moduleName === "finding_patterns" &&
        activeIndex === 3 &&
        _observations.rt_gamepref.responseValue === 1
      ) {
        let a = moduleList[activeIndex];
        a.props.screens.splice(0, 1);
        moduleList.splice(9, 0, a);
      }
      setFinal(y);

      let module_observation = _observations;
      let observationsArray = Object.keys(module_observation).map(function (k) {
        return { ...module_observation[k], ...{ key: k } };
      });
      try {
        elapsedTime = new Date().getTime() - elapsedTime
        await DataService.post("psychometrics/submit", {
          companyId: decoded.company_id,
          integrationId: user.integration_id,
          uid: user.uid,
          module: moduleName,
          observations: observationsArray,
          isLastScreen: isLastScreen,
          elapsedTime
        })
      }
      catch (err) {
        if (err.code === 403) {
          dispatch(setExpired(true));
          sessionExpired()
          return
        }
      }
      if (isLastScreen && activeIndex !== moduleList.length - 1) {
        dispatch(setActiveIndex(activeIndex + 1));
        // module completion
        // finding pattern condition
        // is not a skip option
        progressNavigate(moduleName, activeIndex, _observations)
      } else if (isLastScreen && activeIndex === moduleList.length - 1) {
        dispatch(setCompleted(true));
      }
    }
  }
  const progressNavigate = (moduleName, activeIndex, _observations) => {
    if (moduleName !== "finding_patterns" || activeIndex !== 3 || _observations?.rt_gamepref?.responseValue !== 1) {
      dispatch(progressBarNavigate())
    }
  }
  const onCancel = async (_observations, elapsedTime, moduleName, status) => {
    if (!completed) {
      const obj = { [moduleName]: _observations };
      const y = { ...final, ...obj };
      setFinal(y);
      let module_observation = _observations;
      let observationsArray = Object.keys(module_observation).map(function (k) {
        return { ...module_observation[k], ...{ key: k } };
      });
      try {
        elapsedTime = new Date().getTime() - elapsedTime
        await DataService.post("psychometrics/submit", {
          companyId: decoded.company_id,
          integrationId: user.integration_id,
          uid: user.uid,
          module: moduleName,
          observations: observationsArray,
          isLastScreen: true,
          elapsedTime
        })
        await DataService.post("psychometrics/end-session", {
          companyId: decoded.company_id,
          integrationId: user.integration_id,
          uid: user.uid,
          status: "CANCELLED"
        })
        dispatch(setSurveyCancelled(true))
      }
      catch (err) {
        if (err.code === 403) {
          dispatch(setExpired(true));
          sessionExpired()
          return
        }
      }
    }
  };
  const sendData = () => {
    try {
      dispatch(endSessionAPiCall({
        companyId: decoded.company_id,
        integrationId: user.integration_id,
        uid: user.uid,
        status: "COMPLETED"
      }))
    } catch (err) {
      if (err.code === 403) {
        dispatch(setExpired(true));
        sessionExpired()
      }
    }
  }

  const playerComp = () => {
    return modules ? <Player
      key={`${modules.name}`}
      currentScreen={screen}
      module={{
        fpVariant,
        locale,
        clientName: client.clientName,
        ...modules.props,
        showUid: theme?.showUid || false,
        privacyUrl: callbacks?.privacyUrl || 'https://www.begini.co/privacy/',
        createdAt: client.createdAt,
        isRetry: client.isRetry
      }}
      onModuleFinish={onModuleFinish}
      onScreenSubmit={onScreenSubmit}
      onCancel={onCancel}
      user={user}
    /> : ''
  }

  return (
    <div className="App">
      {loading || !showPlayer ? <LoadingScreen /> : playerComp()}
    </div>
  );
};

export default PlayerContainer;
