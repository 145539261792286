import styled from 'styled-components';

import { Modal, ModalFooter } from 'reactstrap';
import { RaisedButton } from '../../assets/styles/BaseStyle';

export const TimerWrapper = styled.div`
  display: ${({ visible }) => visible ? 'flex' : 'none'};
  background: #DDE2E8;
  padding: 8px;
  width: 140px;
  border-radius: 30px;
  align-items: center;
	justify-content: center;
  position: relative;
  margin: 0 10px;

  &:hover {
      background: #eceff2;
  }
  & svg {
      vertical-align: initial;
  }
  
 ${({ paused }) => paused && `
    z-index: 1001;
 `}
 
 ${({ allow_control }) => allow_control && `
   outline: none;
    box-shadow: 0 0px 6px rgba(0, 0, 0, 0.3), 0 6px 12px rgba(0, 0, 0, 0.4);
    cursor: pointer;
  
    &:active,
    &.active {
      box-shadow: 0 0px 3px rgba(0, 0, 0, 0.2), 0 3px 12px rgba(0, 0, 0, 0.3);
        border: 0;
    }
  
    &:focus {
        outline: none;
    }
 `}
`;

export const Clock = styled.div`
  width: 22px;
  height: 22px;
  background: ${({ theme }) => `${theme.colors.primary}33`};
  border-radius: 50%;
  display: block;

  path {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

export const Time = styled.div`
  font-size: 1.3em;
  line-height: initial;
  color: #969899;
  text-align: center;
  min-width: 45px;
  margin: 0 10px;
`;

export const Status = styled.div`
  font-family: efl_iconfont;
  height: 20px;
  padding: 0 2px;
  color: #66CC33;
`;

export const TimerExtension = styled(Modal)`
  [class~="modal-content"] {
		border: 2px solid ${({ theme }) => theme.colors.primary};
    border-radius: .6rem;
	}
	
  input {
    margin: 10px;
  }
`;

export const TimerExtensionOption = styled.label`
  cursor: pointer;
  display: block;
  background: #F0F3F7;
  padding: 1px;
  border-radius: 4px;
  margin-bottom: 15px;
`;

const TimerButton = styled(RaisedButton)`
  margin: 0 auto;
  border-radius: 20px;
  color: #fff;
  margin: 10px 0;
  display: block;
  border: 0;
  padding: 0.375rem 0.75rem;
  outline: 0;
  min-width: 200px;
  margin: 15px auto 15px !important;

  ${({ disabled }) => disabled && `
    background: #DDE2E8;
    `}

  @media (max-width: 576px) {
    ${({ buttondesign }) => eval(buttondesign) ? `
    margin: 0px !important;
    min-width:125px;
    font-size:14px;
    ` : `
    min-width:200px
    `};
  }
`;

export const TimerCancelButton = styled(TimerButton)`
  background: ${({ theme }) => theme.button.timer.navigation.background};
`;

export const TimerAddButton = styled(TimerButton)`
  background: ${({ theme }) => theme.button.timer.navigation.background};
  
  ${({ disabled }) => disabled && `
    background: #DDE2E8;
    box-shadow: none;
  `}
  ${({ hideAfter }) => hideAfter && `
  :after {
    font-family: efl_iconfont;
    content: '\\54';
  }
  `}
  
`;

export const TimerFooter = styled(ModalFooter)`
  border: 0;
  justify-content: space-between;
  
  ${({ buttondesign }) => eval(buttondesign) ? `
  @media(max-width: 576px) {
    display: flex;
  }
  ` : `
  @media(max-width: 576px) {
    display: block
  }
  `}

`;