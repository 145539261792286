const shuffleArray = (data) =>{
 let arr = data;
   let i = arr.length, k , temp;  
   while(--i > 0){
      var j = window.crypto.getRandomValues(new Uint16Array(1))
      k = Math.floor(j[0]/100000 * (i+1));
      temp = arr[k];
      arr[k] = arr[i];
      arr[i] = temp;
   }
   return arr
}
export default shuffleArray;