/* eslint-disable import/no-anonymous-default-export */
export default {
    screens: [
        {
            observation: 'st_intro',
            type: 'intro',
            module: 'sand_truck',
            meta: {
                v2: true,
                hiddenHeader: true,
                image: 'SandTruck/st_intro'
            },
            auto_advance: true,
            timer: {
                time: 10000,
                extend: false,
                pause: false,
                visible: false
            }
        },
        {
            observation: 'st_exercise_0',
            type: 'truk_module',
            module: 'sand_truck',
            meta: {
                generate: {
                    delay: 59999,
                },
                hideBottumNavigation: true,
                helpImgs: ['SandTruck/st_help1', 'SandTruck/st_help2', 'SandTruck/st_help3'],
                images: ['SandTruck/TRUCK1', 'SandTruck/TRUCK2', 'SandTruck/TRUCK3', 'SandTruck/TRUCK4', 'SandTruck/TRUCK5', 'SandTruck/TRUCK6', 'SandTruck/TRUCK7', 'SandTruck/TRUCK8', 'SandTruck/TRUCK9', 'SandTruck/TRUCK10', 'SandTruck/TRUCK11', 'SandTruck/TRUCK12', 'SandTruck/TRUCK13', 'SandTruck/TRUCK14', 'SandTruck/TRUCK15'],
                InitScore: 2,
                IncrementScore: 5
            },
            help: true,
            auto_advance: false,
            timer: {
                time: 60000,
                extend: false,
                pause: false,
                visible: true
            },
        },
        {
            observation: 'time_expire_sand_truck',
            module: 'sand_truck',
            type: 'time_expire_model',
            auto_advance: true,
            timer: {
                time: 3000,
                extend: false,
                pause: false,
                visible: false
            }
        },
        {
            observation: 'st_score_0',
            type: 'score_board',
            module: "sand_truck",
            meta: {
                preObs: "st_exercise_0",
                status: "dangar",
                image: "SandTruck/TRUCK16"
            },
            next: {
                visible: true,
                enabled: true
            },
            auto_advance: true,
            timer: {
                time: 3000,
                extend: false,
                pause: false,
                visible: false
            }
        },
        {
            observation: 'st_postguess',
            type: 'radio_group',
            meta: {
                center: true,
                defaultValue: null,
                title: {
                    alignment: 'left'
                },
                sub_title: {
                    alignment: 'left'
                },
                options: [
                    { value: "1" },
                    { value: "2" },
                    { value: "3" }
                ]
            },
            module: "sand_truck",
            help: true,
            next: {
                rules: {
                    visible: true,
                    enabled: ['observation']
                },
                visible: true,
                enabled: false
            },
        },
        {
            observation: 'st_redo',
            type: 'radio_group',
            meta: {
                center: true,
                defaultValue: null,
                fontSize: '15px',
                title: {
                    alignment: 'left'
                },
                sub_title: {
                    alignment: 'left'
                },
                options: [
                    { value: "1" },
                    { value: "2" },
                    { value: "3" }
                ]
            },
            module: "sand_truck",
            help: true,
            next: {
                rules: {
                    visible: true,
                    enabled: ['observation']
                },
                visible: true,
                enabled: false
            },
        },
        {
            observation: 'st_exercise_1',
            type: 'truk_module',
            module: 'sand_truck',
            meta: {
                generate: {
                    delay: 59999,
                },
                hideBottumNavigation: true,
                helpImgs: ['SandTruck/st_help1', 'SandTruck/st_help2', 'SandTruck/st_help3'],
                images: ['SandTruck/TRUCK1', 'SandTruck/TRUCK2', 'SandTruck/TRUCK3', 'SandTruck/TRUCK4', 'SandTruck/TRUCK5', 'SandTruck/TRUCK6', 'SandTruck/TRUCK7', 'SandTruck/TRUCK8', 'SandTruck/TRUCK9', 'SandTruck/TRUCK10', 'SandTruck/TRUCK11', 'SandTruck/TRUCK12', 'SandTruck/TRUCK13', 'SandTruck/TRUCK14', 'SandTruck/TRUCK15'],
                InitScore: 2,
                IncrementScore: 3
            },
            help: true,
            auto_advance: false,
            timer: {
                time: 60000,
                extend: false,
                pause: true,
                visible: true
            }
        },
        {
            observation: 'time_expire_sand_truck',
            module: 'sand_truck',
            type: 'time_expire_model',
            auto_advance: true,
            timer: {
                time: 3000,
                extend: false,
                pause: false,
                visible: false
            }
        },
        {
            observation: 'st_score_1',
            type: 'score_board',
            module: "sand_truck",
            meta: {
                preObs: "st_exercise_1",
                status: "dangar",
                image: "SandTruck/TRUCK16"
            },
            auto_advance: true,
            next: {
                visible: true,
                enabled: true
            },
            timer: {
                time: 3000,
                extend: false,
                pause: false,
                visible: false
            }
        },
        {
            type: "module_finish",
            observation: "great_job",
            module: "sand_truck",
            auto_advance: true,
            timer: {
              time: 3000,
              extend: false,
              pause: false,
              visible: false,
            },
            next: {
                visible: true,
                enabled: true
            }
        }
    ],
    skip_keys: {
        st_redo: {
            values: ["3"],
            targets: ['st_exercise_1', 'st_score_1','time_expire_sand_truck']
        },
        st_exercise_0:{
            values: ["danger","success"],
            targets: ['time_expire_sand_truck']
        },
        st_exercise_1:{
            values: ["danger","success"],
            targets: ['time_expire_sand_truck']
        },
        st_score_0:{
            values: ["dangar"],
            targets: ['st_postguess']
        }
    },
    screen_submit:false
}