// eslint-disable-next-line import/no-anonymous-default-export
import fin from "./financial_decisions";

const newJson = {
    screens: fin.screens.map(screen => {
        return {
            ...screen,
            module: 'financial_decisions_euro',
            ...(screen.type === 'fin_choice') && {
                meta: {
                    ...screen.meta,
                    value_1: screen.meta.value_1 + "0", 
                    value_2: screen.meta.value_2 + "0", 
                    prefix: "EUR",
                    ...(['fd_1month1', 'fd_2today1', 'fd_7months1', 'fd_6months1'].includes(screen.observation)) && {
                        generate: {
                            ...screen.meta.generate,
                            delta: screen.meta.generate?.delta !== undefined ? screen.meta.generate.delta * 10 : undefined,
                        }
                    }  
                }
            },
        };
    }),
    skip_keys: Object.fromEntries(
        Object.entries(fin.skip_keys).map(([key, value]) => [
            key,
            {
                values: value.values.map(val => val + "0"), 
                targets: value.targets
            }
        ])
    ),
    screen_submit: true 
};

export default newJson;
