import actions from './actions';
const {
  setThemeAction,
  setClientAction,
  setCallbacksAction
} = actions;

const setClient = (data) => {
  return async dispatch => {
    dispatch(setClientAction(data))
  };
};

const setTheme = (data) => {
  return async dispatch => {
    dispatch(setThemeAction(data))
  };
};
const setCallbacks = (data) => {
  return async dispatch => {
    dispatch(setCallbacksAction(data))
  };
};
export {
  setTheme,
  setClient,
  setCallbacks
};
