import _get from 'lodash.get'
import _shuffle from 'lodash.shuffle'
import { useContext, useEffect, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { GlobalBaseStyle } from '../../assets/styles/BaseStyle'
import { LocaleContext } from '../../context/LocalContext'
import setTheme from '../../context/ThemeProvider'
import { fpLoadImage } from '../../helpers/image_loader'
import useThemeConfig from '../../hooks/useThemeConfig'
import { Checkbox, Img, ImgWraper, Span } from './index'

const RavenPattern = ({ fpVariant, options, observation, imgClicked, theme, variant }) => {
  const translation = useContext(LocaleContext)
  const themeConfig = useThemeConfig()

  const [answerOption, setAnswerOption] = useState([])
  const [userSelected, setUserSelected] = useState()

  useEffect(() => {
    setAnswerOption(_shuffle(options))
    setUserSelected()
  }, [observation])

  return (
    <>
      <GlobalBaseStyle />
      <ThemeProvider theme={setTheme(theme || themeConfig)}>
        <ImgWraper className={`imgGame`}>
          {answerOption.map(({ img, correct }, i) => {
            const { label = '' } = _get(translation, `${observation}.optionValue[${i}]`, [])
            return (
              <Img
                key={i}
                onClick={() => {
                  imgClicked({ correct, label, value: i + 1 })
                  setUserSelected(i)
                }}
              >
                <Checkbox key={i} type='radio' />
                <div className='d-flex justify-content-center align-items-center mt-2'>
                  <p>{String.fromCharCode(97 + i)}</p>
                  <Span selected={userSelected === i}>
                    <span />
                  </Span>
                </div>
                <img style={{ cursor: 'pointer' }} src={fpLoadImage(fpVariant, img, variant)} alt={variant ? 'fp' + fpVariant + img : img} />
              </Img>
            )
          })}
        </ImgWraper>
      </ThemeProvider>
    </>
  )
}

export default RavenPattern
