import React, { useState, useEffect, useRef, useContext } from 'react'
import PropTypes from 'prop-types'



import { GlobalBaseStyle, CenterContent, Question, Hint, Instruction } from '../../assets/styles/BaseStyle';
import { Group, Image, RateStyle, Nav, NavButtons, ArrowRight, TitleContent } from './GroupRadioRatingStyles'
import RadioButton from '../../components/RadioButton/RadioButton'
import { extract_text } from '../../helpers';
import { LocaleContext } from '../../context/LocalContext';

import { loadImage } from '../../helpers/image_loader';

import _get from 'lodash.get';

const GroupRadioRating = ({ fontSize, imgSrc,
  observation, title, options, center, other_option,
  handPointer, value, add_to_state, defaultValue, image,
  onChangeHandler, sub_title, instruction }) => {

  const translation = useContext(LocaleContext);
  const componentRef = useRef(true);
  
  const [selected, setSelected] = useState(_get(value, 'value', null));
  const [selected1, setSelected1] = useState(_get(value, 'value', null));
  const [selected2, setSelected2] = useState(_get(value, 'value', null));

  const [label, setLabel] = useState(null);
  const [label1, setLabel1] = useState(null);
  const [label2, setLabel2] = useState(null);

  const [sequence, setSequence] = useState(_get(value, 'sequence', []));
  const [sequence1, setSequence1] = useState(_get(value, 'sequence', []));
  const [sequence2, setSequence2] = useState(_get(value, 'sequence', []));

  useEffect(() => {
    setSelected(_get(value, 'value', defaultValue || null));
    setSelected1(_get(value, 'value', defaultValue || null));
    setSelected2(_get(value, 'value', defaultValue || null));

    setLabel(_get(value, 'label', null));
    setLabel1(_get(value, 'label', null));
    setLabel2(_get(value, 'label', null));

    setSequence(_get(value, 'sequence', []));
    setSequence1(_get(value, 'sequence', []));
    setSequence2(_get(value, 'sequence', []));

  }, [observation]);

  useEffect(() => {
    if (!componentRef.current && selected) {
      if (typeof onChangeHandler === 'function') {
        
          const state = {};
          if (add_to_state) {
            state[typeof add_to_state === 'string' ? add_to_state : observation] = selected;
          }
          if (selected && selected1 && selected2)
          onChangeHandler({
            value: selected,
            sequence,
            label,
            value1: selected1,
            sequence1,
            label1,
            value2: selected2,
            sequence2,
            label2
          }, state)
          
      }
    } else {
      componentRef.current = false
    }
  }, [selected]);

  useEffect(() => {
    if (!componentRef.current && selected1) {
      if (typeof onChangeHandler === 'function') {
        
          const state = {};
          if (add_to_state) {
            state[typeof add_to_state === 'string' ? add_to_state : observation] = selected1;
          }
          if (selected && selected1 && selected2)
          onChangeHandler({
            value: selected,
            sequence,
            label,
            value1: selected1,
            sequence1,
            label1,
            value2: selected2,
            sequence2,
            label2
          }, state)
      }
    } else {
      componentRef.current = false
    }
  }, [selected1]);

  useEffect(() => {
    if (!componentRef.current && selected2) {
      if (typeof onChangeHandler === 'function') {
        
          const state = {};
          if (add_to_state) {
            state[typeof add_to_state === 'string' ? add_to_state : observation] = selected2;
          }
          if (selected && selected1 && selected2)
            onChangeHandler(
              {
                value: selected,
                sequence,
                label,
                value1: selected1,
                sequence1,
                label1,
                value2: selected2,
                sequence2,
                label2,
              },
              state
            );
      }
    } else {
      componentRef.current = false
    }
  }, [selected2]);

  const handleSelect = (value, label) => {
    setSelected(value);
    setLabel(label);
    setSequence(sequence => {
      if (value !== sequence[sequence.length - 1]) sequence.push(value);
      return sequence
    });
  };

  const handleSelect1 = (value, label) => {
    setSelected1(value);
    setLabel1(label);
    setSequence1(sequence1 => {
      if (value !== sequence1[sequence1.length - 1]) sequence1.push(value);
      return sequence1
    });
  };

  const handleSelect2 = (value, label) => {
    setSelected2(value);
    setLabel2(label);
    setSequence2(sequence2 => {
      if (value !== sequence2[sequence2.length - 1]) sequence2.push(value);
      return sequence2
    });
  };

  const generateOptions = () => {
    const defaultOptions = [...options];
    
    return defaultOptions.map((item, index) => {
      const is_selected = selected === item.value;

      return <RadioButton 
        className="ratingRadio"
        key={index}
        fontSize={fontSize}
        identifier={index}
        onClick={() => handleSelect(item.value, item.label || _get(translation, `${observation}.option${index + 1}`))}
        pointer={handPointer && is_selected}
        selected={is_selected}
        // label={item.label || _get(translation, `${observation}.option${index + 1}`)}
        imgSrc={loadImage(imgSrc[index])}
      />
    });
  };

  const generateOptions1 = () => {
    const defaultOptions = [...options];
    
    return defaultOptions.map((item, index) => {
      const is_selected = selected1 === item.value;

      return <RadioButton
        className="ratingRadio"
        key={index}
        fontSize={fontSize}
        identifier={index}
        onClick={() => handleSelect1(item.value, item.label || _get(translation, `${observation}.option${index + 1}`))}
        pointer={handPointer && is_selected}
        selected={is_selected}
        // label={item.label || _get(translation, `${observation}.option${index + 1}`)}
        imgSrc={loadImage(imgSrc[index])}
      />
    });
  };

  const generateOptions2 = () => {
    const defaultOptions = [...options];
   
    return defaultOptions.map((item, index) => {
      const is_selected = selected2 === item.value;

      return <RadioButton
        className="ratingRadio"
        key={index}
        fontSize={fontSize}
        identifier={index}
        onClick={() => handleSelect2(item.value, item.label || _get(translation, `${observation}.option${index + 1}`))}
        pointer={handPointer && is_selected}
        selected={is_selected}
        imgSrc={loadImage(imgSrc[index])}
        // label={item.label || _get(translation, `${observation}.option${index + 1}`)}
      />
    });
  };
  


  return <>
    <GlobalBaseStyle />
    <RateStyle />
    <CenterContent center={center}>
    {
      !handPointer && <>
      <TitleContent>{_get(translation, `${observation}.title`)}</TitleContent>
      <Question className="qusAlign" alignment={title.alignment}>{extract_text(title) || _get(translation, `${observation}.question1`)}</Question>  
      </>
    }  
    {
      handPointer && <>
      <div className="qusAlign"></div>  
      </>
    }
      <Group>
        {generateOptions()}
      </Group>

    {
      handPointer && <>
      <div className="qusAlign"></div>  
      </>
    }
    
    {
      !handPointer && <>
      <Question className="qusAlign" alignment={title.alignment}>{extract_text(title) || _get(translation, `${observation}.question2`)}</Question>
      
      <Group>
        {generateOptions1()}
      </Group>

      <Question className="qusAlign" alignment={title.alignment}>{extract_text(title) || _get(translation, `${observation}.question3`)}</Question>
      
      <Group>
        {generateOptions2()}
      </Group> 
      </>
    }
    </CenterContent>
  </>
};

GroupRadioRating.defaultProps = {
  options: [],
  value: null,
  handPointer: false,
  sub_title: '',
  title: ''
};

GroupRadioRating.propTypes = {
  value: PropTypes.object,
  handPointer: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number
  ]),
  options: PropTypes.array.isRequired,
  onChangeHandler: PropTypes.func
};

export default GroupRadioRating