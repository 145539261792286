import React from 'react'
import { useHistory } from 'react-router-dom'
import {Main} from './style'

const Expired = ({location}) => {
  const {uid, integration_id} = location?.state || {}
  const history = useHistory()

  const handleClick = () => {
    const url = `/ids?uid=${uid}&&integrationId=${integration_id}`
    history.replace(url)
  }

  return (
    <>
      <Main>
        <h1>Your current session has expired.</h1>
        <h4>Please renew the session to continue the assessment.</h4>
        <button on onClick={handleClick}>
          Renew session
        </button>
      </Main>
    </>
  )
}

export default Expired
