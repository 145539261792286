// eslint-disable-next-line import/no-anonymous-default-export
export default {
    screens: [
        {
            observation: 'rt_gamepref',
            module: 'finding_patterns',
            type: 'radio_group',
            meta: {
                variant: true,
                center: true,
                defaultValue: null,
                title: {
                    alignment: 'left'
                },
                sub_title: {
                    alignment: 'left'
                },
                options: [
                    { value: 1 },
                    { value: 2 }
                ],
            },
            help: true,
            next: {
                rules: {
                    visible: true,
                    enabled: ['observation']
                },
                visible: true,
                enabled: false
            }
        },
        {
            observation: 'rt_intro',
            module: 'finding_patterns',
            type: 'intro',
            meta: {
                variant: true,
                hideHalpNavigation: true,
                hideBottumNavigation: true,
                v2: true,
                image: "rt_intro"
            },
            auto_advance: true,
            timer: {
                time: 10000,
                extend: false,
                pause: false,
                visible: false
            },

        },
        {
            observation: 'rt_preguess',
            module: 'finding_patterns',
            type: 'simple_slider',
            help: true,
            meta: {
                variant: true,
                min: 1,
                max: 100,
                marks: {},
                defaultValue: 50,
                options: [{}, {}, {}],
                center: true,
                sub_title: { as: "title" },
            },
            back: false,
            next: {
                rules: {
                    visible: true,
                    enabled: ['observation']
                },
                visible: true,
                enabled: false
            },
        },
        {
            observation: 'rt_level01',
            module: 'finding_patterns',
            type: 'find_pattern',
            help: true,
            auto_advance: true,
            timer: {
                time: 45000,
                extend: true,
                pause: true,
                visible: true
            },
            meta: {
                variant: true,
                hideHalpNavigation: false,
                hideBottumNavigation: true,
                helpImgs: ["1000", "0200", "0030", "0004"],
                title: { alignment: 'center' },
                jump: 8,
                questionImage: '10000',
                options: [{ img: '11000', correct: true }, { img: '10200', correct: false }, { img: '10030', correct: false }, { img: '10004', correct: false }]
            },
        },
        {
            observation: 'rt_level02',
            module: 'finding_patterns',
            type: 'find_pattern',
            help: true,
            auto_advance: true,
            timer: {
                time: 45000,
                extend: true,
                pause: true,
                visible: true
            },
            meta: {
                variant: true,
                hideHalpNavigation: false,
                helpImgs: ["1000", "0200", "0030", "0004"],
                hideBottumNavigation: true,
                title: { alignment: 'center' },
                jump: 7,
                questionImage: '20000',
                options: [{ img: '21000', correct: false }, { img: '20200', correct: false }, { img: '20030', correct: true }, { img: '20004', correct: false }]
            },
        },
        {
            observation: 'rt_level03',
            module: 'finding_patterns',
            type: 'find_pattern',
            help: true,
            auto_advance: true,
            timer: {
                time: 45000,
                extend: true,
                pause: true,
                visible: true
            },
            meta: {
                variant: true,
                hideHalpNavigation: false,
                helpImgs: ["1000", "0200", "0030", "0004"],
                title: { alignment: 'center' },
                hideBottumNavigation: true,
                jump: 6,
                questionImage: '30000',
                options: [{ img: '31000', correct: false }, { img: '30200', correct: false }, { img: '30030', correct: true }, { img: '30004', correct: false }]
            },
        },
        {
            observation: 'rt_level04',
            module: 'finding_patterns',
            type: 'find_pattern',
            help: true,
            auto_advance: true,
            timer: {
                time: 45000,
                extend: true,
                pause: true,
                visible: true
            },
            meta: {
                variant: true,
                hideHalpNavigation: false,
                jump: 5,
                helpImgs: ["1000", "0200", "0030", "0004"],
                hideBottumNavigation: true,
                title: { alignment: 'center' },
                questionImage: '40000',
                options: [{ img: '41000', correct: false }, { img: '40200', correct: true }, { img: '40030', correct: false }, { img: '40004', correct: false }]
            },
        },
        {
            observation: 'rt_level05',
            module: 'finding_patterns',
            type: 'find_pattern',
            help: true,
            auto_advance: true,
            timer: {
                time: 45000,
                extend: true,
                pause: true,
                visible: true
            },
            meta: {
                variant: true,
                hideHalpNavigation: false,
                jump: 4,
                hideBottumNavigation: true,
                helpImgs: ["1000", "0200", "0030", "0004"],
                title: { alignment: 'center' },
                questionImage: '50000',
                options: [{ img: '51000', correct: true }, { img: '50200', correct: false }, { img: '50030', correct: false }, { img: '50004', correct: false }]
            },
        },
        {
            observation: 'rt_level06',
            module: 'finding_patterns',
            type: 'find_pattern',
            help: true,
            auto_advance: true,
            timer: {
                time: 45000,
                extend: true,
                pause: true,
                visible: true
            },
            meta: {
                variant: true,
                hideHalpNavigation: false,
                jump: 3,
                helpImgs: ["1000", "0200", "0030", "0004"],
                title: { alignment: 'center' },
                hideBottumNavigation: true,
                questionImage: '60000',
                options: [{ img: '61000', correct: true }, { img: '60200', correct: false }, { img: '60030', correct: false }, { img: '60004', correct: false }]
            },
        },
        {
            observation: 'rt_postguess',
            module: 'finding_patterns',
            type: 'numerical_slider',
            help: true,
            meta: {
                center: true,
                min: 0,
                max: 6,
                sub_title: { as: 'title' },
            },
            next: {
                variant: true,
                visible: true,
                enabled: false,
                rules: {
                    visible: true,
                    enabled: [
                        "observation"
                    ]
                }
            }
        },
        {
            observation: 'rt_postguess2',
            module: 'finding_patterns',
            type: 'simple_slider',
            help: true,
            meta: {
                variant: true,
                min: 1,
                max: 100,
                marks: {},
                defaultValue: 50,
                options: [
                    {},
                    {},
                    {}
                ],
                center: true
            },
            back: true,
            next: {
                rules: {
                    visible: true,
                    enabled: ['observation']
                },
                visible: true,
                enabled: false
            },
        },
        {
            type: "module_finish",
            observation: "greatJob",
            module: "finding_patterns",
            auto_advance: true,
            timer: {
                time: 3000,
                extend: false,
                pause: false,
                visible: false,
            },
            next: {
                visible: true,
                enabled: true
            }
        }
    ],
    skip_keys: {
        rt_gamepref: {
            values: ['1'],
            targets: ['rt_preguess', 'rt_intro', 'rt_level01', 'rt_level02', 'rt_level03', 'rt_level04', 'rt_level05', 'rt_level06', 'rt_postguess', 'rt_postguess2']
        }
    },
    screen_submit: false
};