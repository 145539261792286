import React from "react";
import { IntlProvider, FormattedMessage } from "react-intl";
import { Main } from './style';
import useLanguage from "../../hooks/useLanguage";

function Blocked() {
    const { messages, locale, setLocale } = useLanguage()

    return messages ? (
        <IntlProvider locale={locale} messages={messages}>
            <App locale={locale} direction="ltr" onLocaleChange={(locales) => setLocale(locales)} />
        </IntlProvider>
    ) : null;
}
export default Blocked;

function App() {
    return (
        <Main>
            <h1 >
                <FormattedMessage id="accessRestrictedForThisAssessment" />
            </h1>
            <h4 >
                <FormattedMessage id="pleaseContactCompany" />
            </h4>
        </Main>
    );
}
