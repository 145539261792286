import React from 'react';
import ComboBox from './ComboBox/ComboBox';
import GroupRadioButton from './GroupRadioButton/GroupRadioButton';
import GroupRadioButtonImage from './GroupRadioButtonImage/GroupRadioButtonImage'
import GroupCheckboxButton from './GroupCheckboxButton/GroupCheckboxButton';
import ImageSlider from './ImageSlider/ImageSlider';
import NumericalSlider from './NumericalSlider/NumericalSlider';
import TooltipSlider from './TooltipSlider/TooltipSlider';
import TextInput from './TextInput/TextInput';
import Intro from './Intro/Intro';
import Instructions from './Instructions/Instructions';
import GameInstructions from './GameInstructions/GameInstructions';
import DigitSpan from './DigitSpan/DigitSpan';
import SimpleSlider from './SimpleSlider/SimpleSlider';
import ModuleFinish from './ModuleFinish/ModuleFinish';
import MultipleInstruction from './MultipleInstruction/MultipleInstruction';
import ChoiceBox from './ChoiceBox/ChoiceBox';
import GameStart from './GameStart/GameStart';
import GameSimpleSlider from './GameSimpleSlider/GameSimpleSlider';
import GamePage from './GamePage/GamePage';
import GameResult from './GameResult/GameResult';
import FindPattern from './FindPattern/FindPattern';
import FireBuildGame from './FireBuildGame/FireBuildGame';
import DecisionMake from './DecisionMaking/DecisionMake';
import TrukGame from './TrukGame/TrukGame';
import ScoreBoard from './ScoreBoard/ScoreBoard';
import CoinDecision from './CoinsDecision/CoinDecision';
import HoverSlider from './HoverSlider/HoverSlider';
import TimeExpireModel from './TimeExpireModel/TimeExpireModel';
import GroupImageButton from './GroupImageButton/GroupImageButton';
import FillAmount from './FillAmount/FillAmount';
import TransitionStart from './TransitionStart/TransitionStart';
import EndIntro from './TransitionEnd/EndIntro';
import ServeyComplete from './ServeyComplete/ServeyComplete';
import GroupRadioRating from './GroupRadioRating/GroupRadioRating';
import ConfidentialityCheck from './ConfidentialityCheck/ConfidentialityCheck';
import DropDown from './Dropdown/Dropdown';
import ServeyCancelComponent from './ServeyCancel/ServeyCancelComponent'

const Template = ({ module,createdAt, fpVariant, help, translation, journey_state, type, meta, alias, observation, value, defaultValue, handleObservationChange, handleModuleFinish, handleReady, handleNavigation, previousValues, clientName, showUid, uId, privacyUrl, locale }) => {
  const props = { module,createdAt, fpVariant, journey_state, translation, alias, observation, value, defaultValue, previousValues, clientName, showUid, uId, privacyUrl,locale, ...meta };
  const template_map = {
    'checkbox_group': <GroupCheckboxButton {...props} onChangeHandler={handleObservationChange} />,
    'combo_box': <ComboBox {...props} onChangeHandler={handleObservationChange} />,
    'digit_span': <DigitSpan {...props} onChangeHandler={handleObservationChange} onReady={handleReady} handleNavigation={handleNavigation} />,
    'fin_choice': <ChoiceBox {...props} onChangeHandler={handleObservationChange} handleNavigation={handleNavigation} />,
    'image_slider': <ImageSlider {...props} onChangeHandler={handleObservationChange} />,
    'intro': <Intro {...props} onChangeHandler={handleObservationChange} handleNavigation={handleNavigation} />,
    'instructions': <Instructions {...props} onChangeHandler={handleObservationChange} />,
    'multiple_instructions': <MultipleInstruction {...props} onChangeHandler={handleObservationChange} />,
    'module_finish': <ModuleFinish {...props} onChangeHandler={handleObservationChange} handleClick={handleModuleFinish} />,
    'numerical_slider': <NumericalSlider {...props} onChangeHandler={handleObservationChange} />,
    'radio_group': <GroupRadioButton {...props} onChangeHandler={handleObservationChange} />,
    'radio_group_image': <GroupRadioButtonImage {...props} onChangeHandler={handleObservationChange} />,
    'simple_slider': <SimpleSlider {...props} onChangeHandler={handleObservationChange} />,
    'text_input': <TextInput {...props} onChangeHandler={handleObservationChange} />,
    'tooltip_slider': <TooltipSlider {...props} onChangeHandler={handleObservationChange} />,
    'game_instructions': <GameInstructions {...props} onChangeHandler={handleObservationChange} />,
    'game_start': <GameStart {...props} onChangeHandler={handleObservationChange} handleNavigation={handleNavigation} />,
    'game_simple_slider': <GameSimpleSlider {...props} onChangeHandler={handleObservationChange} />,
    'game_page': <GamePage {...props} onChangeHandler={handleObservationChange} handleNavigation={handleNavigation} />,
    'game_result': <GameResult {...props} onChangeHandler={handleObservationChange} />,
    'find_pattern': <FindPattern help={help} {...props} onChangeHandler={handleObservationChange} handleNavigation={handleNavigation} />,
    'fire_building': <FireBuildGame {...props} onChangeHandler={handleObservationChange} handleNavigation={handleNavigation} />,
    'truk_module': <TrukGame {...props} onChangeHandler={handleObservationChange} handleNavigation={handleNavigation} />,
    'balloon_module': <TrukGame {...props} onChangeHandler={handleObservationChange} handleNavigation={handleNavigation} />,
    'score_board': <ScoreBoard {...props} onChangeHandler={handleObservationChange} handleNavigation={handleNavigation} />,
    'pick_img': <DecisionMake {...props} onChangeHandler={handleObservationChange} handleNavigation={handleNavigation} />,
    'coin_game': <CoinDecision {...props} onChangeHandler={handleObservationChange} handleNavigation={handleNavigation} />,
    'hover_slider': <HoverSlider  {...props} onChangeHandler={handleObservationChange} />,
    'time_expire_model': <TimeExpireModel {...props} handleNavigation={handleNavigation} />,
    'group_image_button': <GroupImageButton  {...props} onChangeHandler={handleObservationChange} />,
    'fill_amount': <FillAmount  {...props} onChangeHandler={handleObservationChange} />,
    'start_trans': <TransitionStart {...props} onChangeHandler={handleObservationChange} handleNavigation={handleNavigation} />,
    'start_trans_custom': <TransitionStart {...props} onChangeHandler={handleObservationChange} handleNavigation={handleNavigation} />,
    'end_intro': <EndIntro  {...props} onChangeHandler={handleObservationChange} />,
    'servey_finish': <ServeyComplete {...props} onChangeHandler={handleObservationChange} handleClick={handleModuleFinish} />,
    'servey_rating': <GroupRadioRating {...props} onChangeHandler={handleObservationChange} handleNavigation={handleNavigation} />,
    'confidentiality_check': <ConfidentialityCheck {...props} onChangeHandler={handleObservationChange} handleNavigation={handleNavigation} />,
    'confidentiality_check_custom': <ConfidentialityCheck {...props} onChangeHandler={handleObservationChange} handleNavigation={handleNavigation} />,
    'drop_down': <DropDown {...props} onChangeHandler={handleObservationChange} handleNavigation={handleNavigation} />,
    'cancellation': <ServeyCancelComponent {...props} onChangeHandler={handleObservationChange} handleClick={handleModuleFinish} />,

  };
  return template_map[type] || 'No screens included in config.';
};

export default Template;
