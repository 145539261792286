import React, { Fragment, useContext, useEffect, useState } from "react";

import { GlobalBaseStyle } from "../../assets/styles/BaseStyle";
import { CenteredText, BoxContainer, Option } from "./ChoiceBoxStyle";
import { extract_text } from "../../helpers";

import getSymbolFromCurrency from "currency-symbol-map";
import { LocaleContext } from "../../context/LocalContext";
import _get from "lodash.get";

import numeral from "numeral";

const ChoiceBoxScreen = ({
  observation, title, sub_title, label_1, label_2, suffix, prefix, value_1, value_2, generate,
  onChangeHandler, handleNavigation, previousValues, handPointer, is_first, v2
}) => {
  const [clicked, setClicked] = useState(false);
  const [factorA, setSactorA] = useState(value_1);
  const translation = useContext(LocaleContext);
  useEffect(() => {
    const generateScreenTimeout = _get(generate, "delay");
    const ob = _get(generate, "prev_ob");
    const prevSelect = _get(previousValues, ob, "");
    const del = _get(generate, "delta", 0);
    const { v1 } = choiceFormula(
      del,
      parseInt(factorA),
      parseInt(value_2),
      _get(prevSelect, "label")
    );
    setSactorA(v1);
    setClicked(false);
    if (typeof generateScreenTimeout === "number") {
      setTimeout(() => {
        if (!clicked && !handPointer)  onChangeHandler({ response_options: [v1.toString(),value_2], label: _get(translation, `${observation}.label_1`) || `option_1`, value:value_1 });
      }, generateScreenTimeout);
    }
  }, [observation]);

  useEffect(() => {
    if (clicked) handleNavigation(1);
  }, [clicked]);

  const applyFormatting = (value) => {
    const evaluatedSuffix =
      typeof suffix === "function" ? suffix(value) : suffix;
    const formattedValue = numeral(value).format(`0,0`);

    let formatting = "";

    if (prefix) formatting += `${getSymbolFromCurrency(prefix) || prefix} `;
    formatting += formattedValue;
    if (evaluatedSuffix) formatting += ` ${evaluatedSuffix}`;
    return formatting;
  };

  const choiceMade = (v, l,i) => {
    if (typeof onChangeHandler === "function") {
      setClicked(true);
      onChangeHandler({ value: v.toString(), label: l, response_options: [factorA.toString(),value_2] });
    }
  };

  const choiceFormula = (d, v_1, v_2, select) => {
    if (d === 0) {
      return { v1: value_1, v2: v_2 };
    }
    if (select === "option_1") {
      return { v1: v_1 - d, v2: v_2 };
    } else {
      return { v1: v_1 + d, v2: v_2 };
    }
  };

  return (
    <Fragment>
      <GlobalBaseStyle />
      <div className="row">
        <div className="col-12">
          <CenteredText>
            {extract_text(title) || _get(translation, `${observation}.title`)}
          </CenteredText>
        </div>
        <div className="col-12">
          <div className={`text-center`}>
            {extract_text(sub_title) ||
              _get(translation, `${observation}.sub_title`)}
          </div>
        </div>
        <div className="col-12">
          <BoxContainer>
            <Option pointer={is_first && handPointer} onClick={() => handPointer ||
              choiceMade(factorA, _get(translation, `${observation}.label_1`),value_1)}>
              <Icon value={applyFormatting(factorA)} />
              <span>
                {extract_text(label_1) ||
                  _get(translation, `${observation}.label_1`, 'option_1')}
              </span>
            </Option>
            <Option pointer={!is_first && handPointer} onClick={() => handPointer ||
              choiceMade(value_2, _get(translation, `${observation}.label_2`, 'option_2'),value_2)}>
              <Icon value={applyFormatting(value_2)} />
              <span>
                {extract_text(label_2) ||
                  _get(translation, `${observation}.label_2`)}
              </span>
            </Option>
          </BoxContainer>
        </div>
      </div>
      <div className="row"></div>
    </Fragment>
  );
};
ChoiceBoxScreen.defaultProps = {
  prefix: "USD",
  suffix: "",
};
const Icon = ({ value }) => (
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 136.5 77.21" style={{ enableBackground: 'new 0 0 136.5 77.21' }} >
<style>{`
      .st0{fill:#B9EF85;}
      .st1{fill:#60A15C;}
      .st2{fill:none;stroke:#A0CB64;stroke-miterlimit:10;}
      .st3{fill:none;stroke:#A0CB64;stroke-width:2;stroke-miterlimit:10;}
      .st4{fill:none;stroke:#A0CB64;stroke-width:5.7976;stroke-miterlimit:10;}
      .st5{fill:none;stroke:#A0CB64;stroke-width:5.8132;stroke-miterlimit:10;}
      .st6{fill:#FFFFFF;stroke:#A0CB64;stroke-width:1.2181;stroke-miterlimit:10;}
    `}</style>
<g>
	<g>
		<rect className="st0" width="136.5" height="77.21"/>
	</g>
	<g>
		<path className="st1" d="M126.3,10.66v55.89H10.2V10.66H126.3 M131.3,5.66H5.2v65.89H131.3V5.66L131.3,5.66z"/>
	</g>
	<path className="st1" d="M22.06,5.66c0,9.31-7.55,16.86-16.86,16.86V5.66H22.06z"/>
	<path className="st1" d="M131.3,22.52c-9.31,0-16.86-7.55-16.86-16.86h16.86V22.52z"/>
	<path className="st1" d="M114.44,71.55c0-9.31,7.55-16.86,16.86-16.86v16.86H114.44z"/>
	<path className="st1" d="M5.2,54.68c9.31,0,16.86,7.55,16.86,16.86H5.2V54.68z"/>
	<path className="st2" d="M23.27,63.71c-1.99-4.59-5.65-8.26-10.24-10.24V23.74c4.59-1.99,8.26-5.65,10.24-10.24h89.95
		c1.99,4.59,5.65,8.26,10.24,10.24v29.73c-4.59,1.99-8.26,5.65-10.24,10.24H23.27z"/>
	<circle className="st1" cx="24.06" cy="38.6" r="5.65"/>
	<circle className="st1" cx="112.44" cy="38.6" r="5.65"/>
	<line className="st3" x1="16.41" y1="50.17" x2="37.38" y2="50.17"/>
	<line className="st4" x1="24.06" y1="57.09" x2="37.83" y2="57.09"/>
	<line className="st4" x1="24.06" y1="20.24" x2="41.25" y2="20.24"/>
	<line className="st5" x1="98.59" y1="57.09" x2="112.44" y2="57.09"/>
	<line className="st5" x1="95.51" y1="20.24" x2="112.44" y2="20.24"/>
	<line className="st3" x1="99.12" y1="50.17" x2="120.09" y2="50.17"/>
	<g>
		<ellipse className="st0" cx="68.25" cy="38.6" rx="42.72" ry="36.88"/>
	</g>
	<ellipse className="st6" cx="68.25" cy="38.6" rx="39.42" ry="34.03"/>
</g>
<text x="50%" y="45" textAnchor="middle">{value}</text>
</svg>
);

export default ChoiceBoxScreen;
