import actions from './actions';

const {
  SET_LOADING,
  SET_START_SESSION,
  SET_SURVEY_COMPLETED,
  SET_ERROR,
  SET_EXPIRED,
  SET_SURVEY_CANCELLED, SET_SURVEY_EXPIRED
} = actions;

const initState = {
  loading: true,
  completed: false,
  error: null,
  surveyCompleted: false,
  expired: false,
  surveyCancelled: false,
  surveyExpired: false
};

const AuthReducer = (state = initState, action = {}) => {
  const { type, data, err } = action;
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: data,
      };
    case SET_START_SESSION:
      return {
        ...state,
        startSession: data,
      };
    case SET_SURVEY_COMPLETED:
      return {
        ...state,
        surveyCompleted: data,
      };
    case SET_SURVEY_CANCELLED:
      return {
        ...state,
        surveyCancelled: data,
      };
    case SET_ERROR:
      return {
        ...state,
        error: data || err,
      };
    case SET_EXPIRED:
      return {
        ...state,
        expired: data,
      };
    case SET_SURVEY_EXPIRED:
      return {
        ...state,
        surveyExpired: data,
      };

    default:
      return state;
  }
};
export default AuthReducer;
