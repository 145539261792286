/* eslint-disable default-case */
import _get from 'lodash.get';
import { getText } from '.';

export const instruction_translations = (type, translation, contents) => {
  let translations = [];

  switch (type) {
    case 'digit_span':
    case 'text_input': 
      translations = [
        'To enter text, tap or click above the line.',
        'Use the keyboard to type.'
      ];
      break;
    case 'radio_group':
      translations = [
        'Choose one of the options by tapping or clicking anywhere in the grey area. Your choice is shown by a filled in circle.',
        'To choose a different option, tap or click in a different grey area.'
      ];
      break;
      case 'radio_group_image':
      case 'servey_rating':
      translations = [
        'Choose one of the options by tapping or clicking anywhere in the grey area. Your choice is shown by a filled in circle.',
        'To choose a different option, tap or click in a different grey area.'
      ];
      break;
    case 'checkbox_group':
    case 'confidentiality_check':
      case 'confidentiality_check_custom':
      translations = [
        'Tapaa or click the grey area of the option you want to select. A selected choice will have a Checkmark in it.',
        'Select as many options as required.',
        'To undo your choice, tap or click the grey area again.'
      ];
      break;
    case 'numerical_slider':
      translations = [
        'Press the circle and hold it down.',
        'Drag left or right to move the circle to change the value of your answer.',
        'Move the circle until you’ve chosen the value.'
      ];
      break;
    case 'image_slider':
    case 'simple_slider':
    case 'tooltip_slider':
      translations = [
        'Press the circle and hold it down.',
        'Drag left or right to move the circle.',
        'The closer you move the circle to one of the ends the stronger your answer is. Move the circle to the spot on the slider that represents your answer.'
      ];
      break;
    case 'fill_amount':
    case 'end_intro':
      translations = [
        'Use the plus(+) or minus(-) buttons to increase or decrease a number'
      ];
      break;
  }
  return translations.map((trans, index) => {
    return getText({ contents, translation, observation: type ,key: contents? `help.help_text${index+1}`: `help_text${index+1}`, defaultTxt: trans})        
  });
};