"use strict";

import React, { Fragment, useContext } from "react";
import PropTypes from "prop-types";

import { GlobalBaseStyle, CenterContent } from "../../assets/styles/BaseStyle";
import {
  GameInstructionsImage,
  StartButton,
  StartButtonDiv,
} from "./GameStartStyle";
import { Hint, Question } from "../../assets/styles/BaseStyle";
import { extract_text } from "../../helpers";
import { LocaleContext } from "../../context/LocalContext";
import { loadImage } from "../../helpers/image_loader";
import _get from "lodash.get";

const GameStartScreen = ({
  observation,
  image,
  instruction,
  hint, intro,
  sub_title,
  button_text,
  handleNavigation,
}) => {
  const translation = useContext(LocaleContext);
  const imageType = typeof image;
  const handleButtonClick = () => {
    handleNavigation(1);
  };
  return (
    <Fragment>
      <GlobalBaseStyle />
      <CenterContent center={true}>
        <Question alignment={_get(instruction, "alignment", "center")}>
          {extract_text(instruction) ||
            _get(
              translation,
              `${observation}.title`,
              _get(
                translation,
                `${observation}.instruction`,
                _get(translation, `${observation}.instruction1`)
              )
            )}
          <div className={`py-3 lefl-module-sub-title moduleSubTitle`}>
            {" "}
            {extract_text(sub_title) ||
              _get(translation, `${observation}.sub_title`)}{" "}
          </div>{" "}
          {intro && <div className={`lefl-module-sub-title moduleSubTitle`}>
            {" "}
            {_get(translation, `${observation}.intro`)}{" "}
          </div>}
        </Question>

        <GameInstructionsImage>
          <img src={loadImage(image||'game_candy')} className="center" />
        </GameInstructionsImage>
        <StartButtonDiv>
          <StartButton onClick={handleButtonClick}>
            {extract_text(button_text) ||
              _get(translation, `${observation}.button_text`)}
          </StartButton>
        </StartButtonDiv>
      </CenterContent>
    </Fragment>
  );
};

GameStartScreen.defaultProps = {
  image: "",
};

GameStartScreen.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  sub_title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  image: PropTypes.string,
};

export default GameStartScreen;
