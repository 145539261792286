import styled from "styled-components";

export const Container = styled.div`
  width: 60%;
  margin: 20px auto;
  @media (min-width: 320px) and (max-width: 425px) {
    width: 90%;
  }
`;
export const ScoreLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 5px 10px;
  border-radius: 32px;
  background: #dde2e8;
  outline: none;
  font-size: 19px;
  width: 160px;
  border: 0;
  position: relative;
  height: 100%;
  text-align: center;
  &:hover {
    background: #eceff2;
  }
`;
export const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 35px auto;
  div {
    cursor: pointer;
    margin: auto 15px;
    width: 30px;
    svg {
      width: 30px;
    }
  }

  @media (min-width: 320px) and (max-width: 772px) {
    margin: 80px auto;
    div {
      margin: auto 3px;
      svg {
        width: 22px;
        height: 66px;
      }
    }
  }
`;
