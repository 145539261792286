
import { useSelector } from 'react-redux';

const useThemeConfig = () => {
  const { theme } = useSelector(State => State.config);
  const { primaryColor: primary, secondaryColor: secondary } = theme
  return { primary, secondary }
}

export default useThemeConfig
