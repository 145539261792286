import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import setTheme from '../../context/ThemeProvider';
import useThemeConfig from '../../hooks/useThemeConfig';
import { drawStacks } from '../../lib/util';
import { Container, ProgressBlock } from './ProgressBarStyles';

const ProgressBar = () => {
  const [stacks, setStacks] = useState([])
  const [loaded, setLoaded] = useState(false)
  const themeConfig = useThemeConfig();
  const { progressbar: { total = 9, completed = 0 } } = useSelector(State => State.module);

  useEffect(() => {
    if (stacks.length > 0) {
      let index = completed - 1
      if (total > index && index > -1) {
        const st = stacks.map((c, i) => {
          return (i === index) ? true : c
        });
        setStacks(st)
      }
    }
  }, [completed])

  useEffect(() => {
    if (loaded) {
      const st = stacks.map((c, i) => {
        return (i < completed) ? true : c
      });
      setTimeout(() => {
        setStacks(st)
      }, 100);
    }
  }, [loaded])

  useEffect(() => {
    setStacks(drawStacks(total, 0))
    setLoaded(true)
  }, [total])

  return <>
    <Container>
      <ProgressBlock theme={setTheme(themeConfig || {})}>
        {stacks.length > 0 && <ul className='dashed-progress-bar' >
          {stacks.map((i, k) => (
            <li key={k} className={i ? 'active' : ''}><span></span></li>
          ))}
        </ul>
        }
      </ProgressBlock>
    </Container>
  </>
}
export default ProgressBar;
