/* eslint-disable import/no-anonymous-default-export */
export default {
    screens: [
        {
            observation: 'fc_intro',
            module: 'flashcards',
            type: 'intro',
            prograssBar: false,
            meta: {
                hiddenHeader: true,
                v2: true,
                image: 'fc_intro'
            },
            auto_advance: true,
            timer: {
                time: 10000,
                extend: false,
                pause: false,
                visible: false
            },
        },
        {
            observation: 'fc_preguess',
            module: 'flashcards',
            type: 'simple_slider',
            prograssBar: false,
            help: true,
            back: true,
            meta: {
                min: 0,
                max: 20,
                marks: {},
                defaultValue: 10,
                options: [
                    {},
                    {},
                    {}
                ],
                center: true
            },
            next: {
                rules: {
                    visible: true,
                    enabled: ['observation']
                },
                visible: true,
                enabled: false
            },
        },
        {
            observation: 'fc_start_0',
            module: 'flashcards',
            type: 'game_start',
            prograssBar: false,
            meta: {
                hideBottumNavigation: true,
                image:  ["game_candy", "game_tree", "game_fish", "game_insects"][Math.floor(Math.random() * 4)],
                instruction: { alignment: 'center' },
                sub_text: true,
                intro: true,
            },
            auto_advance: true,
            timer: {
                time: 18000,
                extend: false,
                pause: false,
                visible: false
            },
            next: {
                visible: false,
                enabled: false
            },
        },
        {
            observation: 'fc_cards_0',
            module: 'flashcards',
            type: 'game_page',
            auto_advance: true,
            timer: {
                time: 45000,
                extend: false,
                pause: false,
                visible: true
            },
            prograssBar: false,
            meta: {
                keybord: "keybord",
                v2: true,
                image: "game_candy",
                images: ["game_candy", "game_tree", "game_fish", "game_insects"],
                levelCompleteImage: "level-complete-image1",
                randomNumbers: Array.from({ length: 45 }, () =>
                    Math.floor(Math.random() * 4)
                ),
                rotationtime: 1000
            },
            next: {
                visible: false,
                enabled: false
            },
        },
        {
            observation: 'time_expire',
            module: 'flashcards',
            type: 'time_expire_model',
            prograssBar: false,
            auto_advance: true,
            timer: {
              time: 3000,
              extend: false,
              pause: true,
              visible: false,
            },
        },
        {
            observation: 'fc_postguess',
            module: 'flashcards',
            prograssBar: false,
            type: 'simple_slider',
            help: true,
            meta: {
                min: 1,
                max: 100,
                marks: {},
                defaultValue: 50,
                options: [{}, {}, {}],
                center: true
            },
            back: true,
            next: {
                rules: {
                    visible: true,
                    enabled: ['observation']
                },
                visible: true,
                enabled: false
            },
        },
        {
            observation: 'fc_redo',
            type: 'radio_group',
            prograssBar: false,
            meta: {
                center: true,
                defaultValue: null,
                title: {
                    alignment: 'center'
                },
                sub_title: {
                    alignment: 'center'
                },
                options: [
                    { value: "1" },
                    { value: "2" },
                    { value: "3" }
                ]
            },
            back: true,
            next: {
                rules: {
                    visible: true,
                    enabled: ['observation']
                },
                visible: true,
                enabled: false
            },
            module: 'flashcards'
        },
        {
            observation: 'fc_start_1',
            module: 'flashcards',
            type: 'game_start',
            prograssBar: false,
            meta: {
                hideBottumNavigation: true,
                image:  ["game_candy", "game_tree", "game_fish", "game_insects"][Math.floor(Math.random() * 4)],
                instruction: { alignment: 'center' },
                sub_text: true,
                intro: true,
            },
            auto_advance: true,
            timer: {
                time: 18000,
                extend: false,
                pause: false,
                visible: false
            },
            next: {
                visible: false,
                enabled: false
            },
        },
        {
            observation: 'fc_cards_1',
            module: 'flashcards',
            type: 'game_page',
            auto_advance: true,
            timer: {
                time: 45000,
                extend: false,
                pause: false,
                visible: true
            },
            prograssBar: false,
            meta: {
                keybord: "keybord",
                v2: true,
                image: "game_candy",
                images: ["game_candy", "game_tree", "game_fish", "game_insects"],
                levelCompleteImage: "level-complete-image1",
                randomNumbers: Array.from({ length: 45 }, () =>
                    Math.floor(Math.random() * 4)
                ),
                rotationtime: 1000
            },
            next: {
                visible: false,
                enabled: false
            },
        },
        {
            observation: 'time_expire',
            module: 'flashcards',
            type: 'time_expire_model',
            prograssBar: false,
            auto_advance: true,
            timer: {
              time: 3000,
              extend: false,
              pause: true,
              visible: false,
            },
        },
        {
            type: "module_finish",
            observation: "greatJob",
            module: "flashcards",
            auto_advance: true,
            timer: {
              time: 3000,
              extend: false,
              pause: false,
              visible: false,
            },
            next: {
                visible: true,
                enabled: true
            }
        }
    ],
    skip_keys: {
        fc_redo: {
            values: ["3"],
            targets: ['fc_start_1', 'fc_cards_1','time_expire']
        },
    },
    screen_submit:false
};