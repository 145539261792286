import Cookies from 'js-cookie'
import { decrypt, encrypt } from '../lib/util'
import { COOKIE } from './constant'
export default class CookieHelper {
    static set(key, value, options) {
        Cookies.set(key, encrypt(JSON.stringify(value)), options)
    }
    static get(key) {
        try {
            let value = decrypt(Cookies.get(key))
            value = JSON.parse(value)
            return value
        } catch {
            return null
        }
    }
    static clearAllCookie() {
        Cookies.remove(COOKIE.TOKEN)
        Cookies.remove(COOKIE.USER)
    }

}