import { combineReducers } from 'redux';
import authReducer from './authentication/authReducer';
import moduleReducer from './module/moduleReducer';
import configReducer from './config/configReducer';

const rootReducers = combineReducers({
  auth: authReducer,
  module: moduleReducer,
  config:configReducer
});

export default rootReducers;
