import { Modal, ModalBody } from "reactstrap";

import styled from 'styled-components';

export const Group = styled.div`
  text-align: left;
  label {
    font-size: 20px;
  }
  @media (max-width:767px){
    label {
      font-size: 16px;
    }
  }
  ${({ type }) => type === 'image' && `
    display: flex;
    flex-wrap: wrap;
  `}
`;

export const Title = styled.div`
  text-align: left;
  font-size: 24px;
  margin: 20px auto;

`;
export const SubContent = styled.div`
  text-align: left;
  font-size: 20px;
  margin: 20px auto;
  a {
    color: rgba(0,0,0,.87);
    text-decoration: underline;
  }
  @media (max-width:767px){
    font-size: 16px;
  }
`;
const StyledButton = styled.button`
  align-items: center;
  justify-content: center;
  padding: 3px 14px;
  border-radius: 30px;
  background:  ${({ theme }) => theme.button.navigation.background};
  outline: none;
  font-size: 19px;
  color: white;
  margin: 0 10px;
  border: 0;
  &:disabled {
    background: #eceff2;
    cursor: not-allowed;
  };
  &:hover {
    background: #eceff2;
  };
  
`;
export const NextButton = ({ disabled = false, label, onClick }) => {
  return <StyledButton disabled={disabled} onClick={() => onClick(disabled)}>{label}</StyledButton>
}
export const PopModalBody = styled(ModalBody)`
padding: 25px;
text-align: center;
  div {
    font-size: 20px;
  }
  p {
    margin: 0px 30px;
  }
  @media (min-width: 320px) and (max-width: 425px) {
    div {
      margin: 15px 15px;
      font-size: 20px;
    }
    p {
      margin: 0px 15px;
    }
  }
`;
export const PopUp = styled(Modal)`
  [class~="modal-content"] {
    margin-top: 20%;
    border-radius: 20px;
  }
  @media (min-width: 320px) and (max-width: 425px) {
    [class~="modal-content"] {
      width: auto;
      margin: 35% 13px;
    }
  }
`;

export const NextBtn = styled.div`
  text-align: right !important;
  margin-top: 20px;
`;
export const ContentSub = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;
