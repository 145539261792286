import actions from './actions';
const {
  setLoadingAction,
  setStartSessionAction,
  setSurveyCompletedAction,
  setErrorAction,
  setExpiredAction,
  setSurveyCancelledAction,
  setSurveyExpiredAction
} = actions;


const setLoading = (data) => {
  return async dispatch => {
    dispatch(setLoadingAction(data))
  };
};

const setStartSession = (data) => {
  return async dispatch => {
    dispatch(setStartSessionAction(data))
  };
};

const setSurveyCompleted = (data) => {
  return async dispatch => {
    dispatch(setSurveyCompletedAction(data))
  };
};

const setSurveyCancelled = (data) => {
  return async dispatch => {
    dispatch(setSurveyCancelledAction(data))
  };
};

const setError = (data) => {
  return async dispatch => {
    dispatch(setErrorAction(data))
  };
};

const setExpired = (data) => {
  return async dispatch => {
    dispatch(setExpiredAction(data))
  };
};
const setSurveyExpired = (data) => {
  return async dispatch => {
    dispatch(setSurveyExpiredAction(data))
  };
};

export {
  setLoading,
  setStartSession,
  setSurveyCompleted,
  setError,
  setExpired,
  setSurveyCancelled,
  setSurveyExpired
};
