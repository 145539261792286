import styled from "styled-components";
import { RaisedButton } from "../../assets/styles/BaseStyle";

export const CenteredText = styled.div`
  text-align: center;
  margin-top: 20px;
`;

export const GameInstructionsImage = styled.div`
  width: ${({ width }) => width}%;

  width: 100%;
  display: flex;
  justify-content: center;
  
  @media (max-width: 600px) {
    width: 100%;
  }

  img {
    text-align: center;
    max-width: 75%;
  }
`;
export const StartButtonDiv = styled.div`
  display: flex;
  justify-content: center;
`;

export const StartButton = styled(RaisedButton)`
  width: 150px;
  height: 35px;
  border-radius: 50px;
  font-size: 20px;
  margin-top: 50px;
  text-align: center;
  border: 0;
  color: #fff;
  background: ${({ theme }) => theme.colors.primary};

  @media (max-width: 600px) {
    width: 150px;
  }
  @media (max-width: 1024px) {
  }
`;
