import React, { useContext, useState, useEffect } from "react";
import PropTypes from 'prop-types';
import _get from "lodash.get";

import { GlobalBaseStyle, CenterContent, Question } from "../../assets/styles/BaseStyle";
import { GameOptionButton, GameImage, AddedFire, ExitButtons, TextDiv } from "./FireBuildGameStyle"
import { extract_text } from "../../helpers";
import { LocaleContext } from "../../context/LocalContext";
import { loadImage } from "../../helpers/image_loader";

const FireBuildGame = ({ locale, observation, title, title_2, image, option, onChangeHandler, handleNavigation, generate, button_text }) => {
  const translation = useContext(LocaleContext);
  const [campFire, setCampFire] = useState(image);
  const [fireAdded, setFireAdded] = useState(false);
  const [fireAddedImage, setFireAddedImage] = useState()
  const [firePercentage, setFirePercentage] = useState(0);
  const [woods, setWoods] = useState([]);
  const [exitGame, setExitGame] = useState();
  const [timeSequence, setTimeSequence] = useState([])
  let currentTime = Date.now()

  useEffect(() => {
    if (typeof onChangeHandler === "function") {
      const generateScreenTimeout = _get(generate, 'delay', 5000);
      if (firePercentage === 0) {
        setTimeout(() => {
          onChangeHandler({
            value: 'auto',
            label: 'fire',
            noaction: firePercentage === 0,
            timesfilled: woods.length,
            final: `${firePercentage}%`,
            selection: woods,
            timeSequence
          });
        }, generateScreenTimeout);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (exitGame && typeof handleNavigation === "function") {
      handleNavigation(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exitGame]);

  useEffect(() => {
    if (firePercentage > 0 && firePercentage < 180) {

      let camp = Math.round((firePercentage / 2) * .37);
      setCampFire(`fire_build_game/fireFN${camp}`);
      setTimeout(() => setFireAdded(false), 1000);
      if (typeof onChangeHandler === "function") {
        onChangeHandler({
          value: 'fire',
          label: 'fire',
          noaction: firePercentage === 0,
          timesfilled: woods.length,
          final: `${firePercentage}%`,
          selection: woods,
          timeSequence
        });
      }
    } else if (firePercentage >= 180) {
      if (typeof onChangeHandler === "function") {
        onChangeHandler({
          value: 'exceed',
          label: 'fire',
          noaction: firePercentage === 0,
          timesfilled: woods.length,
          final: `${firePercentage}%`,
          selection: woods,
          timeSequence
        });

        setExitGame(true)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firePercentage]);

  const addFire = (percentage, type) => {

    const time = Date.now() - currentTime
    setTimeSequence([...timeSequence, time])

    let fire = firePercentage + percentage
    setFirePercentage(fire);
    setFireAddedImage(type)
    setFireAdded(true);
    setWoods([...woods, type])


  }
  const donePlaying = () => {
    if (typeof onChangeHandler === "function") {
      onChangeHandler({
        value: 'done',
        label: 'fire',
        noaction: firePercentage === 0,
        timesfilled: woods.length,
        final: `${firePercentage}%`,
        selection: woods,
        timeSequence
      });
      setExitGame(true)
    }
  }
  return (<>
    <GlobalBaseStyle />
    <CenterContent center={true} style={{ width: '100%' }} >
      <Question alignment={_get(title, "alignment", "center")}>
        {extract_text(title) ||
          _get(
            translation,
            `${observation}.title`
          )}
      </Question>
      <GameImage>
        <TextDiv>
          <div className="superMain">
            <div className="subImdTxt">
              <p className={locale}><span className="apOne">{_get(translation, `${observation}.imgText1`)}</span>
                <span className="apTwo">{_get(translation, `${observation}.imgText2`)}</span>
                <span className="apThree">{_get(translation, `${observation}.imgText3`)}</span>
                <span className="apFour">{_get(translation, `${observation}.imgText4`)}</span>
              </p>
            </div>
            <img src={loadImage(campFire)} className="center" alt="game_image" />
            {fireAdded && <AddedFire>
              <Icon text={_get(
                translation,
                `${observation}.${fireAddedImage}`
              )} />  </AddedFire>}
          </div>
        </TextDiv>
      </GameImage>
      <div className="row">
        <div className="col-3">
          <GameOptionButton >
            <img role="presentation" onClick={() => addFire(5, 'twig_added')} src={loadImage(option[0])} className="center" alt="game_option_1" />
            <span className="button_otp"> {_get(
              translation,
              `${observation}.option_1`, 'Add a twig')}</span>
          </GameOptionButton>
        </div>
        <div className="col-3">
          <GameOptionButton >
            <img role="presentation" onClick={() => addFire(20, 'branch_added')} src={loadImage(option[1])} className="center" alt="game_option_2" />
            <span className="button_otp"> {_get(
              translation,
              `${observation}.option_2`, 'Add a branch')}</span>
          </GameOptionButton>
        </div>
        <div className="col-3">
          <GameOptionButton >
            <img role="presentation" onClick={() => addFire(35, 'logs_added')} src={loadImage(option[2])} className="center" alt="game_option_3" />
            <span className="button_otp"> {_get(
              translation,
              `${observation}.option_3`, 'Add logs')} </span>
          </GameOptionButton>
        </div>
        <div className="col-3">
          <GameOptionButton >
            <img role="presentation" onClick={() => addFire(50, 'large_log_added')} src={loadImage(option[3])} className="center" alt="game_option_4" />
            <span className="button_otp"> {_get(
              translation,
              `${observation}.option_4`, 'Add large log')}</span>
          </GameOptionButton>
        </div>
        <div className="col-12">
          <ExitButtons onClick={donePlaying}>
            {extract_text(button_text) ||
              _get(
                translation,
                `${observation}.button_text`
              )}
          </ExitButtons>
        </div>
      </div>
    </CenterContent>
  </>)
}
function Icon({ text }) {
  return (
    <svg width="223" height="61" fill="none" viewBox="0 0 223 61" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id="a" x1="46.724" y1="16.503" x2="26.76" y2="39.298" gradientUnits="userSpaceOnUse">
          <stop stopColor="#CB6B14" />
          <stop offset=".159" stopColor="#C76815" />
          <stop offset=".339" stopColor="#BA6016" />
          <stop offset=".53" stopColor="#A75316" />
          <stop offset=".729" stopColor="#8D4314" />
          <stop offset=".932" stopColor="#6D300F" />
          <stop offset="1" stopColor="#62290D" />
        </linearGradient>
      </defs>
      <g>
        <path d="M221.927 41.62c0 4.316-3.511 7.827-7.826 7.827H21.511c-4.316 0-7.827-3.512-7.827-7.827V13.825c0-4.316 3.511-7.826 7.827-7.826h192.663c4.315 0 7.826 3.51 7.826 7.826V41.62h-.073z" fill="#C6C6C6" />
        <path d="M221.927 41.62c0 4.316-3.511 7.827-7.826 7.827H21.511c-4.316 0-7.827-3.512-7.827-7.827V13.825c0-4.316 3.511-7.826 7.827-7.826h192.663c4.315 0 7.826 3.51 7.826 7.826V41.62h-.073z" stroke="#fff" strokeWidth="2" strokeMiterlimit="10" />
      </g>
      <text
        x="65"
        y="32"
        data-name="Log Added"
        fontFamily="Roboto-Regular, Roboto"
        fontSize="14"
        opacity="1"
        fill="#2F2F2F">
        <tspan x="0" y="0" className="text_added"></tspan>
        {text}
      </text>

      <path d="M50.915 60.052h-.732L1.323 18.14l-.074-.22C1.176 17.7-.36 12.873 2.347 7.68 5.565 1.61 11.343.658 14.489.658c2.048 0 3.437.366 3.51.366l.147.073 12.727 9.948L53.987 27.94l13.678 11.557.073.22c.073.365 1.755 9.362-1.682 14.628-3.365 5.34-12.435 5.706-15.141 5.706z" fill="url(#a)" />
      <path d="M14.489 1.463c1.974 0 3.291.366 3.291.366l12.58 9.874L53.476 28.6l13.458 11.337s1.756 8.997-1.536 14.117c-3.218 5.047-12.434 5.34-14.482 5.34h-.44L1.982 17.774S.445 13.093 3.005 8.12c2.926-5.632 8.119-6.656 11.483-6.656zm0-1.463c-3.292 0-9.436.95-12.8 7.388C-1.165 12.8.444 17.994.517 18.213l.146.366.293.292 48.568 41.547.366.365h.95c2.78 0 12.142-.439 15.726-5.998 3.512-5.485 1.83-14.775 1.756-15.14l-.073-.513-.366-.365-13.459-11.338h-.146L31.165 10.533 18.586.73l-.22-.219-.292-.073C18.146.366 16.61 0 14.488 0z" fill="#fff" />
      <g opacity=".65" fill="#D87823">
        <path d="M9.442 11.558c3.364 2.048 6.363 4.608 8.996 7.46-3.291-2.048-6.363-4.535-8.996-7.46zM19.755 12.8a72.436 72.436 0 0 1 11.41 9.656 72.441 72.441 0 0 1-11.41-9.655zM45.283 39.791a42.773 42.773 0 0 1 9.362 7.241c-3.438-2.048-6.583-4.461-9.362-7.241zM17.707 24.87c2.487 1.462 4.608 3.291 6.29 5.559-2.487-1.463-4.535-3.292-6.29-5.56zM24.436 27.064c2.487 1.463 4.608 3.291 6.29 5.559-2.413-1.463-4.534-3.291-6.29-5.559zM13.464 4.9c2.487 1.464 4.609 3.292 6.29 5.56-2.413-1.463-4.534-3.365-6.29-5.56zM50.768 48.715c2.487 1.463 4.608 3.292 6.29 5.56-2.413-1.464-4.534-3.292-6.29-5.56zM35.188 25.894a72.441 72.441 0 0 1 11.41 9.655 72.436 72.436 0 0 1-11.41-9.655zM52.45 37.743a33.172 33.172 0 0 1 8.412 6.144c-3.072-1.609-5.924-3.657-8.411-6.144zM4.98 12.8a72.434 72.434 0 0 1 11.41 9.656A79.715 79.715 0 0 1 4.98 12.8zM47.184 29.77c2.194 1.17 4.096 2.707 5.632 4.755-2.267-1.17-4.096-2.78-5.632-4.755zM38.919 41.692c2.121 1.024 3.95 2.56 5.413 4.39-2.122-1.025-3.95-2.561-5.413-4.39zM31.75 24.796c2.195 1.17 4.097 2.707 5.633 4.755a17.616 17.616 0 0 1-5.632-4.755z" />
      </g>
      <path d="M35.115 35.33v-8.558h-8.558v-7.534h8.558V10.68h7.607v8.558h8.558v7.534h-8.558v8.558h-7.607z" fill="#2FAC66" />
      <path d="M41.991 11.412v8.558h8.558v6.144h-8.558v8.485h-6.144V26.04h-8.558V19.97h8.558v-8.558h6.144zm1.463-1.463h-9.07v8.558h-8.558v9.07h8.558v8.558h9.07v-8.558h8.558v-9.07h-8.558V9.949z" fill="#fff" />
    </svg>

  );
}
FireBuildGame.propTypes = {
  observation: PropTypes.object,
  title: PropTypes.string,
  title_2: PropTypes.string,
  image: PropTypes.string,
  option: PropTypes.array,
  onChangeHandler: PropTypes.func.isRequired,
  handleNavigation: PropTypes.func.isRequired,
  generate: PropTypes.object,
  button_text: PropTypes.string,
  locale: PropTypes.string.isRequired,
};
export default FireBuildGame;