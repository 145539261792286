import { TextDiv } from "./style"
import { TextDiv as GameDiv } from "../../templates/FireBuildGame/FireBuildGameStyle"

import { loadImage } from '../../helpers/image_loader';
const FireImage = ({ locale, image, text, type }) => {
  const Comp = type === 'intro' ? TextDiv : GameDiv;

  return (<Comp type={type}>
    <div className="superMain">
      <div className="subImdTxt">
        <p className={`${locale} ${type}`}>
          <span className="apOne">{text?.imgText1}</span>
          <span className="apTwo">{text?.imgText2}</span>
          <span className="apThree">{text?.imgText3}</span>
          <span className="apFour">{text?.imgText4}</span>
        </p>
      </div>
      <img src={loadImage(image)} className="center" alt="game_image" />
    </div>
  </Comp>)
}
export default FireImage