import React from "react";
import { IntlProvider, FormattedMessage } from "react-intl";
import useLanguage from "../../hooks/useLanguage";
import { Main } from './style';

function NotFound() {
    const { messages, locale, setLocale } = useLanguage()

    return messages ? (
        <IntlProvider locale={locale} messages={messages}>
            <App locale={locale} direction="ltr" onLocaleChange={(locales) => setLocale(locales)} />
        </IntlProvider>
    ) : null;
}
export default NotFound;

function App({ _locale, _direction, _onLocaleChange }) {

    return (
        <Main>
            <h1 >
                <FormattedMessage id="wcantLetYouIn" />.
            </h1>
            <h4 >
                <FormattedMessage id="missingReuirement" />
            </h4>
            <h4>
                <FormattedMessage id="contactCompany" />
            </h4>
        </Main>
    );
}
