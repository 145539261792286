import React from 'react'
import styled from 'styled-components'

export const LoadingDiv = styled.div`
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url('./spin.svg') 
              50% 50% no-repeat rgb(249,249,249);
`;
const LoadingScreen = () => {
  return <LoadingDiv/>
}

export default LoadingScreen
