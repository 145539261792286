const { images } = require('./assets.json');

const image_map = {};
images.forEach(image => {
  const _name = image.split('.')[0];
  image_map[_name] = require(`../assets/images/${image}`)
});

export const loadImage = (image) => {
  return image_map[image]?.default
}
export const fpLoadImage = (fpVariant, img, variant = true) => {
  const _img = variant ? 'finding_pattern/fp' + fpVariant + img : img
  return loadImage(_img)
}
export const fpHelpLoadImage = (fpVariant, img, variant = true) => {
  const _img = variant ? `finding_pattern/help/fp${fpVariant}h${img}` : img
  return loadImage(_img)
}
