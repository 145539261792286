const initialState = {
  startSession: false,
  moduleList: [],
  modules: [],
  activeIndex: 0,

  theme: {},
  client: {},

  loading: false,
  completed: false,
  error: null,
  surveyCompleted: false,
  expired: false
};

export const GetInitialState = () => ({ ...initialState });

const actionTypes = {
  SET_MODULE_LIST: "SET_MODULE_LIST",
  SET_MODULES: "SET_MODULES",
  SET_ACTIVE_INDEX: "SET_ACTIVE_INDEX",
  SET_THEME: "SET_THEME",
  SET_LOADING: "SET_LOADING",
  SET_COMPLETED: "SET_COMPLETED", //  need to call end session api
  SET_ERROR: "SET_ERROR",
  SET_START_SESSION: "SET_START_SESSION",
  SET_CLIENT: "SET_CLIENT",
  SET_CURRENT_MODULE: "SET_CURRENT_MODULE",
  SET_CURRENT_SCREEN: "SET_CURRENT_SCREEN",
  SET_SURVEY_COMPLETED: "SET_SURVEY_COMPLETED", //  Survey completed, need to show a suvery already completed page
  EXPIRED: "EXPIRED" // EXPIRED
};

export const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_MODULE_LIST:
      return {
        ...state,
        moduleList: action.payload,
        modules: action.payload[state.activeIndex],
      };

    case actionTypes.SET_MODULES:
      return { ...state, modules: action.payload };

    case actionTypes.SET_CURRENT_MODULE:
      return { ...state, module: action.payload };

    case actionTypes.SET_CURRENT_SCREEN:
      return { ...state, screen: action.payload };

    case actionTypes.SET_ACTIVE_INDEX:
      return { ...state, activeIndex: action.payload };

    case actionTypes.SET_THEME:
      return { ...state, theme: action.payload };

    case actionTypes.SET_LOADING:
      return { ...state, loading: action.payload };

    case actionTypes.SET_COMPLETED:
      return { ...state, completed: action.payload };

    case actionTypes.SET_ERROR:
      return { ...state, error: action.payload };

    case actionTypes.SET_START_SESSION:
      return { ...state, startSession: action.payload };

    case actionTypes.SET_CLIENT:
      return { ...state, client: action.payload };

    case actionTypes.SET_SURVEY_COMPLETED:
      return { ...state, surveyCompleted: action.payload };
    case actionTypes.EXPIRED:
      return { ...state, expired: action.payload };

    default:
      return state;
  }
};

export const getActions = (dispatch) => ({
  setModuleList: (moduleList) =>
    dispatch({ type: actionTypes.SET_MODULE_LIST, payload: moduleList }),

  setCurrentModule: (currentModule) =>
    dispatch({ type: actionTypes.SET_CURRENT_MODULE, payload: currentModule }),

  setCurrentScreen: (currentScreen) =>
    dispatch({ type: actionTypes.SET_CURRENT_SCREEN, payload: currentScreen }),

  setModules: (modules) =>
    dispatch({ type: actionTypes.SET_MODULES, payload: modules }),

  setActiveIndex: (activeIndex) =>
    dispatch({ type: actionTypes.SET_ACTIVE_INDEX, payload: activeIndex }),

  setTheme: (theme) =>
    dispatch({ type: actionTypes.SET_THEME, payload: theme }),

  setLoading: (loading) =>
    dispatch({ type: actionTypes.SET_LOADING, payload: loading }),

  setCompleted: (completed) =>
    dispatch({ type: actionTypes.SET_COMPLETED, payload: completed }),

  setError: (error) =>
    dispatch({ type: actionTypes.SET_ERROR, payload: error }),

  setStartSession: (startSession) =>
    dispatch({ type: actionTypes.SET_START_SESSION, payload: startSession }),

  setClient: (client) =>
    dispatch({ type: actionTypes.SET_CLIENT, payload: client }),

  setSurveyCompleted: (completed) =>
    dispatch({ type: actionTypes.SET_SURVEY_COMPLETED, payload: completed }),

  setExpired: (completed) =>
    dispatch({ type: actionTypes.EXPIRED, payload: completed }),
});
