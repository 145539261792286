'use strict'

import ReactI18n, {t} from 'react-i18n'
import _get from 'lodash.get'

class Language {
	setDictionary = (dictionary = {}) => {
		this.dictionary = dictionary
		this.pluralSuffix = '_plural'
		ReactI18n.dictionary = dictionary
	}

	getDictionary = () => {
		return this.dictionary
	}

	translate = (key = '', params = {}) => {
		const {pluralSuffix} = this
		const pluralCount = _get(params, 'plural.count')

		if (typeof pluralCount === 'number' && pluralCount != 1 && _get(this.dictionary, `${key}${pluralSuffix}`)) {
			key += pluralSuffix
		}

		return t(key, params)
	}
}

const language = new Language()

export default language

