
import {
  ArrowLeft,
  ArrowRight,
  Header,
  Nav,
  NavButtons,
  Overlay,
} from "./PlayerStyle";
import { LocaleContext, translations } from "../../context/LocalContext";
import React, { useEffect, useRef, useState } from "react";
import {
  RuleManager,
  useLanguageSequence,
  useNavigationState,
  useScreenTimer,
  useScreenViewCounter,
  useTranslationManager,
} from "../../hooks";
import {
  nextScreenChecker,
  observationState,
  screenManager,
  useRandomScreen,
} from "../../helpers/player";

import { CenterContent } from "../../assets/styles/BaseStyle";
import HelperModal from "../HelperModal/HelperModal";
import Template from "../../templates/Template";
import { ThemeProvider } from "styled-components";
import Timer from "../Timer/Timer";
import _get from "lodash.get";
import { combineObservations } from "../../helpers/observations";
import setTheme from "../../context/ThemeProvider";
import useThemeConfig from "../../hooks/useThemeConfig";
import { useDispatch } from "react-redux";
import { setActiveScreenStore } from "../../redux/module/actionCreator";
import { activeScreenIndex } from "../../lib/util";

const Player = ({
  module,
  theme,
  navigate,
  navigation,
  header,
  onNavigate,
  onObservation,
  onModuleFinish,
  currentScreen,
  user,
  onScreenSubmit,
  onCancel
}) => {
  const dispatch = useDispatch();
  const [elapsedTime, setElapsedTime] = useState(new Date().getTime())
  const screens = useRandomScreen(module) || [];
  const themeConfig = useThemeConfig();
  const index = screens.findIndex((item) => item.observation === currentScreen);
  const screenRef = useRef(true);
  const [holder, setDataToHold] = useState({});
  const [journey_state, setJourneyState] = useState(module.state || {});
  const [helper_state, setHelperModalState] = useState({});
  const [ready_state, setReadyState] = useState({});
  const [overlay_state, setOverlayState] = useState(false);
  const [observations, setObservations] = useState({});
  const [activeScreen, setActiveScreen] = useState(index > 0 ? index : 0);
  const screen = screenManager(module.common, screens, activeScreen);

  const translation = useTranslationManager(
    screen.observation,
    module.locale,
    translations,
    { observation: screen.observation, state: journey_state }
  );
  const screen_timers = useScreenTimer(screen.observation);
  const locales = useLanguageSequence(screen.observation, module.locale);
  const screen_views = useScreenViewCounter(screen.observation);
  const buttonStates = useNavigationState(screen, {
    observations: holder.answer,
    screen_name: screen.observation,
    ready_state,
  });
  
  const handleObservationChange = (value, state = {}) => {
    setDataToHold({
      answer:
        (screen.observation === "fc_cards_0" || screen.observation === "fc_cards_1") ?
          { ...holder.answer, [value.value.card]: value }
          : value,
      state: { ...journey_state, ...state },
    });
  };
  useEffect(() => {
    if (typeof onObservation === "function") onObservation(observations);
  }, [observations]);

  useEffect(() => {
    if (navigate !== "reset") {
      handleNavigation(navigate);
      if (typeof onNavigate === "function") onNavigate();
    }
  }, [navigate]);

  useEffect(() => {
    dispatch(setActiveScreenStore(screen.observation));
    setDataToHold({
      answer: _get(observations, `${screen.module}.${screen.observation}`),
      state: journey_state,
    });
  }, [screen.observation]);
  useEffect(() => {
    // console.log(screen)
  }, [screen.module]);

  const handleReady = (observation, state = true) => {
    const newReadyState = { ...ready_state };
    newReadyState[observation] = state;

    setReadyState(newReadyState);
  };
  const handleCancel = () => {
    const screenObservation = {
      [screen.module]: {
        [screen.observation]: holder?.answer || null,
      },
    }
    let obs = combineObservations(
      screen.module, module, screens, screenObservation,
      screen_views, screen_timers, helper_state.help_timers,
      locales
    )
    onCancel(obs, elapsedTime, screen.module)

  }

  const handleNavigation = (value, send_observation = false, cancelled = false, _holder=null) => {
    let newObservations = observations;
    if (cancelled) {
      return handleCancel()
    }
    if (value === 1) {
      newObservations = observationState(screen, observations,_holder?.answer|| holder.answer);
      setObservations(newObservations);
      setJourneyState(holder.state);
    }

    setActiveScreen((activeScreenValue) => {
      const newActiveScreen = nextScreenChecker(
        value,
        activeScreenValue,
        screens,
        newObservations,
        module.skip_keys
      );
      const is_last_screen = newActiveScreen > screens.length - 1;

      const current_screen_members = screens
        .map((_screenItem, i) => {
          const _screen = screenManager(module.common, screens, i);

          if (screens[activeScreenValue].module === _screen.module) return i;
        })
        .filter((idx) => idx !== undefined);

      const is_last_in_module =
        newActiveScreen >
        current_screen_members[current_screen_members.length - 1];
      if (module.screen_submit) {
        const screenObservation = {
          [screen.module]: {
            [screen.observation]: holder?.answer || null,
          },
        };
        handleScreenSubmit(screen.module, screenObservation, is_last_screen);
      }
      if (
        !module.screen_submit &&
        (send_observation || is_last_screen || is_last_in_module)
      )
        handleModuleFinish(screen.module, newObservations, is_last_screen);
      return handleNavigationEnd(is_last_screen, newActiveScreen, activeScreenValue)
    });
  };

  const handleNavigationEnd = (is_last_screen, newActiveScreen, activeScreenValue) => {
    if (is_last_screen || newActiveScreen < 0) {
      return activeScreenValue;
    } else {
      screenRef.current = false;
      return newActiveScreen;
    }
  }

  const handleBackNavigation = () => {
    if (buttonStates.back.enabled) {
      handleNavigation(-1);
    }
  };

  const handleForwardNavigation = () => {
    let observation = _get(holder, `answer`, {});
    observation.endTime = new Date().getTime()
    const can_navigate_forward = screen.required ? observation !== null : true;
    if (buttonStates.next.enabled && can_navigate_forward) handleNavigation(1);
  };

  const onModuleChange = () => {
    setElapsedTime(new Date().getTime())
  }
  const onScreenChange = () => {
    setElapsedTime(new Date().getTime())
  }

  const handleModuleFinish = async (
    module_name,
    observationsHmf,
    finish = false
  ) => {
    if (typeof onModuleFinish === "function") {

      onModuleFinish(
        combineObservations(
          module_name,
          module,
          screens,
          observationsHmf,
          screen_views,
          screen_timers,
          helper_state.help_timers,
          locales
        ),
        elapsedTime,
        module_name,
        finish
      );
      onModuleChange()
    }
  };

  const handleScreenSubmit = async (
    module_name,
    observationsHss,
    finish = false
  ) => {
    if (typeof onScreenSubmit === "function") {
      onScreenSubmit(
        combineObservations(
          module_name,
          module,
          screens,
          observationsHss,
          screen_views,
          screen_timers,
          helper_state.help_timers,
          locales
        ),
        elapsedTime,
        module_name,
        finish
      );
      onScreenChange()
    }
  };

  const shouldAutoAdvance = (negate_rule = false) => {
    let advance = false;

    // Check auto_advance rule once timer expires
    const auto_advance_type = typeof screen.auto_advance;
    const auto_advance = screen.auto_advance;

    if (auto_advance_type === "boolean" && auto_advance) {
      advance = true;
    } else if (auto_advance_type === "object") {
      const rule = RuleManager(auto_advance.rules || [], {
        observations: _get(holder, `answer`),
      });
      advance = negate_rule ? !rule : rule;
    }

    return advance;
  };

  const handleTimerExpire = () =>{
    if(shouldAutoAdvance()){
      if (['financial_decisions', 'financial_decisions_euro'].includes(screen.module) && screen.meta) {
        let _holder= {answer: { value: screen?.meta?.value_1, label: _get(translation, `${screen?.observation}.label_1`), response_options: [screen?.meta?.value_1,screen?.meta?.value_2] }}
        setDataToHold(_holder)
        handleNavigation(1, false, false, _holder);
        return
      }
      handleNavigation(1);
    } 
  }

  const handleTimerPause = (paused) => setOverlayState(paused);

  const handleAllowTimerExtend = (_holder) => {
    const extend = _get(screen, "timer.extend", false);

    let allowExtend = extend;

    if (extend) {
      allowExtend = shouldAutoAdvance(true);
    }

    return allowExtend;
  };

  const setTimerPause = (ready_state) => {
    if (helper_state.open) return true;

    return !RuleManager(_get(screen, "timer.rules", []), {
      screen_name: screen.observation,
      ready_state,
    });
  };

  return (
    <div>
      <ThemeProvider theme={setTheme(theme || themeConfig)}>
        <Overlay show={overlay_state} />
        <LocaleContext.Provider value={translation}>
          <CenterContent
            hidden={_get(screen, "meta.hiddenHeader", false)}
            center={header && header.center}
          >
            <Header space={_get(screen, "timer.visible", false)}>
              <Timer
                timerId={screen.observation}
                time={_get(screen, "timer.time", 0)}
                timerPaused={setTimerPause(ready_state)}
                allowExtension={handleAllowTimerExtend(holder)}
                enableControlButton={_get(screen, "timer.pause", false)}
                visible={_get(screen, "timer.visible", false)}
                extentionPopup={_get(screen, "timer.extentionPopup", 0)}
                extentionButtonDesign={_get(
                  screen,
                  "timer.extentionButtonDesign",
                  "false"
                )}
                mobileLogoVisible={_get(screen, "mobileLogoVisible", false)}
                timerFinishedHandler={handleTimerExpire}
                timerPausedHandler={handleTimerPause}
                timerExtensionCancelledHandler={() => handleNavigation(1)}
                translation={translation}
              />
              <HelperModal
                ready_state={ready_state}
                help={screen.help }
                screen={{ ...screen.meta, fpVariant: module.fpVariant }}
                type={screen.type}
                observation={screen.observation}
                module={screen.module}
                journey_state={journey_state}
                helpModalStateHandler={(state) => setHelperModalState(state)}
                locale={module.locale}
              />
            </Header>
          </CenterContent>
          <div>
            <Template
              {...screen}
              module={screen.module}
              clientName={module.clientName}
              showUid={module.showUid}
              privacyUrl={module.privacyUrl}
              uId={user.uid}
              journey_state={journey_state}
              handleObservationChange={handleObservationChange}
              handleReady={handleReady}
              previousValues={_get(observations, `${screen.module}`, null)}
              value={_get(
                observations,
                `${screen.module}.${screen.observation}`,
                null
              )}
              translation={translation}
              handleModuleFinish={() => handleNavigation(1, observations)}
              handleNavigation={handleNavigation}
              fpVariant={module.fpVariant}
              createdAt={module.createdAt}
              locale={module.locale}
            />
          </div>
          <CenterContent
            hidden={_get(screen, `meta.hideBottumNavigation`, false)}
            center={navigation && navigation.center}
          >
            <Nav>
              <NavButtons
                visible={buttonStates.back.visible}
                enabled={buttonStates.back.enabled}
                onClick={handleBackNavigation}
              >
                <ArrowLeft />
              </NavButtons>
              <NavButtons
                visible={buttonStates.next.visible}
                enabled={buttonStates.next.enabled}
                onClick={handleForwardNavigation}
              >
                <ArrowRight />
              </NavButtons>
            </Nav>
          </CenterContent>
        </LocaleContext.Provider>
      </ThemeProvider>
    </div>
  );
};

Player.defaultProps = {
  navigate: "reset",
  navigation: {
    center: true,
  },
  header: {
    center: true,
  },
};

export default Player;