import trans from './trans_start'

const newJson = {
    screens: trans.screens.map(screen => {
        return {
            ...screen,
            module: 'trans_start_custom',
            ...screen.observation === 'confidentialityStart' && {
                type: 'confidentiality_check_custom',
                observation: 'confidentialityStart_custom',
            },
            ...screen.observation === 'journey_start' && {
                observation: 'journey_start_custom',
            }
        }
    }),
    screen_submit: true
}
export default newJson