import axios from 'axios'
import { API_RESPONSE_CODE, COOKIE } from '../../helpers/constant'
import CookieHelper from '../../helpers/cookieHelper'
import { ForbiddenError } from '../../helpers/exceptions'

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

const authHeader = () => {
  const token = CookieHelper.get(COOKIE.TOKEN)
  let headers = {}
  if (token) {
    headers = {
      Authorization: `Bearer ${CookieHelper.get(COOKIE.TOKEN)}`,
      'Content-Type': 'application/json'
    }
  }
  return headers
}

const client = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
  },
})


class DataService {
  static async call(options) {
    return new Promise(async (resolve, reject) => {
      try {
        const headers = authHeader()
        if (!headers) {
          throw new ForbiddenError()
        }
        let response = await axios({
          ...options,
          headers: { ...headers, ...options.headers },
        })

        return resolve(response.data.data || response.data)

      } catch (err) {
        reject(err)
      }
    })
  }

  static async get(path = '', params = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        const headers = authHeader()
        if (!headers) {
          throw new ForbiddenError()
        }
        let response = await client({
          method: 'GET',
          url: path,
          headers: { ...headers },
          params: params,
        })

        if (response?.data?.status === API_RESPONSE_CODE.SUCCESS) {
          return resolve(response.data.data)
        } else if (response?.data?.status === API_RESPONSE_CODE.ERROR) {
          throw response.data.error
        }
      } catch (err) {
        reject(err)
      }
    })
  }

  static async post(path = '', data = {}, optionalHeader = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        const headers = authHeader()
        if (!headers) {
          throw new ForbiddenError()
        }
        let response = await client({
          method: 'POST',
          url: path,
          data,
          headers: { ...headers, ...optionalHeader },
        })
        if (response?.data?.status === API_RESPONSE_CODE.SUCCESS) {
          return resolve(response.data.data)
        } else if (response?.data?.status === API_RESPONSE_CODE.ERROR) {
          throw response.data.error
        } else if (response?.data?.status === API_RESPONSE_CODE.FAILED) {
          throw response.data.error
        }
      } catch (err) {
        reject(err)
      }
    })
  }

  static async patch(path = '', data = {}) {
    try {
      let response = await client({
        method: 'PATCH',
        url: path,
        data: JSON.stringify(data),
        headers: { ...authHeader() },
      })

      if (response?.data?.status === API_RESPONSE_CODE.SUCCESS) {
        return response.data.data
      } else if (response?.data?.status === API_RESPONSE_CODE.ERROR) {
        throw response.data.error
      }
    } catch (err) {
      throw new Error(err)
    }
  }

  static async put(path = '', data = {}) {
    try {
      let response = await client({
        method: 'PUT',
        url: path,
        data: JSON.stringify(data),
        headers: { ...authHeader() },
      })
      if (response?.data?.status === API_RESPONSE_CODE.SUCCESS) {
        if (response.data?.data) return response.data.data
        return response.data
      } else if (response?.data?.status === API_RESPONSE_CODE.ERROR) {
        throw response.data.error
      }
    } catch (err) {
      throw new Error(err)
    }
  }

  static async delete(path = '', data = {}, optionalHeader = {}) {
    try {
      let response = await client({
        method: 'DELETE',
        url: path,
        data,
        headers: { ...authHeader(), ...optionalHeader },
      })

      if (response?.data?.status === API_RESPONSE_CODE.SUCCESS) {
        return response.data.data
      } else if (response?.data?.status === API_RESPONSE_CODE.ERROR) {
        throw response.data.error
      }
    } catch (err) {
      throw new Error(err)
    }
  }

}

client.interceptors.response.use(
  response => response,
  error => {
    /**
     * Do something in case the response returns an error code [3**, 4**, 5**] etc
     * For example, on token expiration retrieve a new access token, retry a failed request etc
     */
    const { response } = error
    if (response) {
      return response
    }
    return Promise.reject(error)
  }
)
export { DataService }
