// eslint-disable-next-line import/no-anonymous-default-export
export default {
    screens: [
        {
            observation: 'journey_start',
            module: 'trans_start',
            type: 'start_trans',
            help: false,
            meta: {
                hiddenHeader: true,
                v2: true
            },
            auto_advance: true,
            timer: {
                time: 30000,
                extend: false,
                pause: false,
                visible: false
            },
            screen_submit: true,
            mobileLogoVisible: true,
        },
        {
            type: 'confidentiality_check',
            observation: 'confidentialityStart',
            module: 'trans_start',
            help: true,
            meta: {
                hideHalpNavigation: true,
                title: {
                    alignment: 'left'
                },
                center: true,
                options: [
                    {
                        value: 1,
                        label: "Yes"
                    },

                    {
                        value: 0,
                        label: "No"
                    }
                ],
            },
            back: false,
            next: {
                rules: {
                    visible: true,
                    enabled: ['observation']
                },
                visible: true,
                enabled: false
            },
            mobileLogoVisible: true,
        },
    ],
    screen_submit: true

}