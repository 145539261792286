/* eslint-disable complexity */
const setTheme = ({
  primary,
  secondary,
  alternate,
  font_color_1,
  font_color_2,
  font_color_3,
  navigation,
  help,
  action,
  progress_bar,
  timer,
}) => {
  const _primary = primary || '#BD1E6F'
  const _secondary = secondary || '#BD0E6F'
  const _alternate = alternate || '#DDE2E8'

  const _font_color_1 = font_color_1 || '#FFFFFF'
  const _font_color_2 = font_color_2 || '#212121'
  const _font_color_3 = font_color_3 || '#67CA37'

  const _action = {
    background: _secondary || '#BD1E6F',
    color: _font_color_1 || '#BD1E6F',
    ...(action),
  }

  const _navigation = {
    background: _primary,
    color: _font_color_1,
    ...(navigation),
  }

  const _help = {
    background: _alternate,
    color: _font_color_3,
    navigation: {
      background: _primary || '#BD1E6F',
      alternate: _secondary || '#BD1E6F',
      color: _font_color_1 || '#BD1E6F',
    },
    ...(help),
  }

  const _timer = {
    navigation: {
      background: _primary || '#BD1E6F',
      alternate: _secondary || '#BD1E6F',
      color: _font_color_1 || '#BD1E6F',
    },
    ...(timer),
  }

  const button = {
    navigation: _navigation || '#BD1E6F',
    action: _action || '#BD1E6F',
    help: _help || '#BD1E6F',
    timer: _timer || '#BD1E6F',
  }

  const header = {
    progress_bar: _primary || 'blue',
    ...(progress_bar),
  }

  const colors = {
    primary: _primary || '#BD1E6F',
    secondary: _secondary || '#BD1E6F',
    alternate: _alternate || '#BD1E6F',
    font_color_1: _font_color_1 || '#BD1E6F',
    font_color_2: _font_color_2 || '#BD1E6F',
    font_color_3: _font_color_3 || '#BD1E6F',
  }

  const radio = {
    background: _primary || '#BD1E6F',
  }

  const checkbox = {
    background: _primary || '#BD1E6F',
  }
  return {
    colors,
    header,
    button,
    radio,
    checkbox,
    timer,
  }
}

export default setTheme
