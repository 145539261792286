import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IntlProvider } from "react-intl";
import { CenterContent, GlobalBaseStyle } from "../assets/styles/BaseStyle";
import { useHistory } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { loadImage } from "../helpers/image_loader";
import useLanguage from "../hooks/useLanguage";
import { ThankYouButton, ThankYouDesc, ThankYou, ThankYouContainer } from "../templates/ServeyComplete/ServeyCompleteStyles";
import useThemeConfig from "../hooks/useThemeConfig";
import setTheme from "../context/ThemeProvider";
import LoadingScreen from "../components/LoadingScreen";
import { ArrowLeft, ArrowRight, Nav, NavButtons } from "../components/Player/PlayerStyle";
import { setHeader } from "../redux/module/actionCreator";

const Body = ({ messages }) => {

    const history = useHistory()
    const dispatch = useDispatch()
    const { endSessionApi: { loading } } = useSelector(State => State.module);
    const { callbacks, theme, client } = useSelector(State => State.config);
    const { surveyCompleted } = useSelector(State => State.auth);
    const themeConfig = useThemeConfig();

    useEffect(() => {
        dispatch(setHeader(true))
    }, []);
    useEffect(() => {
        if (surveyCompleted && callbacks.successUrl) {
            setTimeout(() => {
                handleForwardNavigation()
            }, 4000);
        }
    }, [surveyCompleted]);
    const handleForwardNavigation = () => {
        window.location.href = callbacks.successUrl
    }

    useEffect(() => {
        if (!client || !client.uid) {
            // no uid
            history.push('/unauthorized');
        }
    }, [client]);

    return <>
        <GlobalBaseStyle />
        <ThemeProvider theme={setTheme(themeConfig || {})}>
            <CenterContent center={true} >
                {
                    loading ? (<LoadingScreen />) : (
                        <>
                            <ThankYouContainer>
                                <img src={loadImage('transition/thankyou')} alt={messages?.surveyCompleteTitle} />
                                <ThankYou>
                                    {messages?.surveyCompleteTitle}
                                </ThankYou>
                                <div hidden={!theme?.showUid} className={`lefl-module-sub-title moduleSubTitle`}>
                                    {messages?.surveyCompleteSubTitle}
                                    {client?.uid} </div>
                                <ThankYouDesc></ThankYouDesc>
                                <ThankYouButton />
                            </ThankYouContainer>
                            <CenterContent>
                                <Nav>
                                    <NavButtons
                                        visible={false}
                                    >
                                        <ArrowLeft />
                                    </NavButtons>
                                    <NavButtons
                                        visible={surveyCompleted && callbacks.successUrl}
                                        enabled={true}
                                        onClick={handleForwardNavigation}
                                    >
                                        <ArrowRight />
                                    </NavButtons>
                                </Nav>
                            </CenterContent>
                        </>
                    )
                }
            </CenterContent>
        </ThemeProvider>
    </>
}

function ThankYouPage() {
    const { messages, locale, setLocale } = useLanguage()

    return messages ? (
        <IntlProvider locale={locale} messages={messages}>
            <Body messages={messages} locale={locale} direction="ltr" onLocaleChange={(locales) => setLocale(locales)} />
        </IntlProvider>
    ) : null;
}

export default ThankYouPage;