import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 10px;
  margin-top: 0;
  position: relative;
`;

export const Bar = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.header.progress_bar};
  width: 100%;
  height: 100%;
  opacity: 0.5;
`;

export const Progress = styled.div`
  background: ${({ theme }) => theme.header.progress_bar};
  height: 10px;
  width: ${({ progress }) => progress}%;
`;
export const ProgressBlock = styled.div`
  .dashed-progress-bar {
    margin: 8px 0;
    display: flex;
    align-items: center;
    padding: 0px;
    li {
      height: 5px;
      flex: 1 1;
      list-style: none;
      background-color: #dbdbdb;
      & + li {
        margin-left: 4px;
      }
      &.active {
        span {
          width: 100%;
          background-color: ${({ theme }) => theme.header.progress_bar};
          transition: all 0.3s;
        }
      }
      span {
        display: block;
        width: 0%;
        height: 100%;
        transition: all 0.3s;
      }
    }
  }
`;

