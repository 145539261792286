import actions from './actions';

const {
  SET_CLIENT,
  SET_THEME,
  SET_CALLBACKS
} = actions;

const initState = {
  client: null,
  theme: {
    "logo": "https://assets.begini.co/begini/logo.png",
    "secondaryColor": "#008000",
    "primaryColor": "#FF0000",
    "height": 60,
    "companyName": "ABC Company"
  },
  callbacks: {
    successUrl: null,
    failureUrl: null
  }
};

const ConfigReducer = (state = initState, action = {}) => {
  const { type, data } = action;
  switch (type) {
    case SET_CLIENT:
      return {
        ...state,
        client: data
      };
    case SET_THEME:
      return {
        ...state,
        theme: data,
      };
    case SET_CALLBACKS:
      return {
        ...state,
        callbacks: data || initState.callbacks
      };
    default:
      return state;
  }
};
export default ConfigReducer;
