// eslint-disable-next-line import/no-anonymous-default-export
export default {
  screens: [
    {
      observation: 'intro_busGrap',
      module: 'bus_info',
      type: 'intro',
      meta: {
        image: 'calculator_disc',
        agentImg: true,
      },
      //  // auto_advance: true,
      //   timer: {
      //     time: 5000,
      //     extend: false,
      //     pause: false,
      //     visible: false
      //   },
      next: {
        visible: true,
        enabled: true
      },
    },
    {
      type: 'checkbox_group',
      observation: 'familySonsDaughters',
      help: true,
      meta: {
        title: {
          alignment: 'left'
        },
        center: true,
        options: [
          {
            value: 1,
          },
          {
            value: 2,
          },
          {
            value: 3,
          },
          {
            value: 4,
          },
          {
            value: 5,
          },
          {
            value: 6,
          }
        ],
        clearing_options: {
          values: [6],
          rule: 'exclusive'
        },
      },
      back: false,
      next: {
        rules: {
          visible: true,
          enabled: ['observation']
        },
        visible: true,
        enabled: false
      },
      module: 'bus_info'
    },
    {
      type: "radio_group",
      observation: "main_decis_mak",
      module: "bus_info",
      meta: {
        title: {
          alignment: 'left'
        },
        center: true,
        options: [
          {
            value: 1
          },
          {
            value: 2
          }
        ]
      },
      help: true,
      back: false,
      next: {
        visible: true,
        enabled: false,
        rules: {
          visible: true,
          enabled: [
            "observation"
          ]
        }
      }
    },
    {
      type: 'text_input',
      observation: 'business_age_text',
      help: true,
      meta: {
        center: true,
        questions: [{
          observation: 'business_age',
          data_type: 'number',
          validation: {
            rule: '^[0-9]+$',
            empty: 'Please enter valid number',
          }
        }]
      },
      back: false,
      next: {
        rules: {
          visible: true,
          enabled: ['observation']
        },
        visible: true,
        enabled: false
      },
      module: 'bus_info'
    },
    {
      type: 'text_input',
      observation: 'busnum_text',
      help: true,
      meta: {
        center: true,
        questions: [{
          observation: 'busnum',
          data_type: 'number',
          validation: {
            rule: '^[0-9]+$',
            empty: 'Please enter valid number',
          }
        }]
      },
      back: false,
      next: {
        rules: {
          visible: true,
          enabled: ['observation']
        },
        visible: true,
        enabled: false
      },
      module: 'bus_info'
    },
    {
      type: 'text_input',
      observation: 'business_revenues',
      help: true,
      meta: {
        center: true,
        questions: [{
          observation: 'busnum',
          data_type: 'number',
          validation: {
            rule: '^[0-9]+$',
            empty: 'Please enter valid number',
          }
        }]
      },
      back: false,
      next: {
        rules: {
          visible: true,
          enabled: ['observation']
        },
        visible: true,
        enabled: false
      },
      module: 'bus_info'
    },
    {
      observation: 'primary_bus_name',
      key: 'tf'
    },
    {
      observation: 'primary_bus_address',
      key: 'tf'
    },
    {
      type: 'checkbox_group',
      observation: 'bus_activity',
      help: true,
      meta: {
        title: {
          alignment: 'left'
        },
        center: true,
        options: [
          {
            value: 1,
          },
          {
            value: 2,
          },
          {
            value: 3,
          },
          {
            value: 4,
          },
          {
            value: 5,
          },
          {
            value: 6,
          },
          {
            value: 7,
          },
          {
            value: 8,
          },
          {
            value: 9,
          },
          {
            value: 10,
          },
          {
            value: 11,
          },
          {
            value: 12,
          },
          {
            value: 13,
          },
          {
            value: 14,
          },
          {
            value: 15,
          },
          {
            value: 16,
          },
          {
            value: 17,
          },
          {
            value: 18,
          },
          {
            value: 19,
          }
        ]
      },
      back: false,
      next: {
        rules: {
          visible: true,
          enabled: ['observation']
        },
        visible: true,
        enabled: false
      },
      module: 'bus_info'
    },
    {
      observation: 'customer_count',
      key: 'textN'
    },
    {
      observation: 'employee_count',
      key: 'textN'
    },
    {
      observation: 'work_hours_weekly',
      key: 'textN'
    },
    {
      observation: "customer_special_offer",
      key: "rg"
    },
    {
      observation: "advert_6months",
      key: "rg"
    },
    {
      type: 'checkbox_group',
      observation: 'advert_type_6months',
      help: true,
      meta: {
        title: {
          alignment: 'left'
        },
        center: true,
        options: [
          {
            value: 1,
          },
          {
            value: 2,
          },
          {
            value: 3,
          },
          {
            value: 4,
          },
          {
            value: 5,
          },
          {
            value: 6,
          },
          {
            value: 7,
          },
          {
            value: 8,
          },
          {
            value: 9,
          }
        ]
      },
      back: false,
      next: {
        rules: {
          visible: true,
          enabled: ['observation']
        },
        visible: true,
        enabled: false
      },
      module: 'bus_info'
    },
    {
      type: "radio_group",
      observation: "bus_perf_review",
      module: "bus_info",
      meta: {
        title: {
          alignment: 'left'
        },
        center: true,
        options: [
          {
            value: 1
          },
          {
            value: 2
          },
          {
            value: 3
          },
          {
            value: 4
          }
        ]
      },
      help: true,
      back: false,
      next: {
        visible: true,
        enabled: false,
        rules: {
          visible: true,
          enabled: [
            "observation"
          ]
        }
      }
    },
    {
      observation: "bus_cost_budget",
      key: "rg"
    },
    {
      observation: "bus_sales_30days",
      key: 'CRN'
    },
    {
      observation: "bus_profit_30days",
      key: 'CRN'
    },
    {
      type: 'text_input',
      help: true,
      observation: 'bus_expense_30_days',
      meta: {
        center: true,
        title: {
          alignment: 'left'
        },
        questions: [{
          data_type: 'text',
          observation: "bus_expense_goods",
        },
        {
          data_type: 'text',
          observation: "bus_expense_utilities",
        },
        {
          data_type: 'text',
          observation: "bus_expense_marketing",
        },
        {
          data_type: 'text',
          observation: "bus_expense_interest",
        },
        {
          data_type: 'text',
          observation: "bus_expense_salary",
        },
        {
          data_type: 'text',
          observation: "bus_expense_rent",
        },
        {
          data_type: 'text',
          observation: "bus_expense_other",
        },
        {
          data_type: 'text',
          observation: "bus_expense_30days",
        }
        ],
        currency: {
          symbl: "$"
        }
      },
      back: false,
      next: {
        rules: {
          visible: true,
          enabled: ['observation']
        },
        visible: true,
        enabled: false
      },
      module: 'bus_info'
    },
    {
      observation: "bus_revenue_12months",
      key: "CRN"
    },
    {
      observation: "bus_revenue_start",
      key: "CRN"
    },
    {
      observation: "bus_investment_start",
      key: "CRN"
    },
    {
      observation: "monthly_cashflow",
      key: "CRN"
    },
    {
      observation: "amount_receivables",
      key: "CRN"
    },
    {
      observation: "amount_payables",
      key: "CRN"
    },
    {
      observation: "owe_debtors",
      key: "CRN"
    },
    {
      observation: "owe_creditors",
      key: "CRN"
    },
    {
      observation: "amount_bad_debt",
      key: "CRN"
    },
    {
      observation: "inventory_count",
      key: "textN"
    }
  ],
  common: {
    tf: {
      type: 'text_input',
      help: true,
      meta: {
        center: true,
        questions: [{
          validation: {
            empty: true
          }
        }]
      },
      back: false,
      next: {
        rules: {
          visible: true,
          enabled: ['observation']
        },
        visible: true,
        enabled: false
      },
      module: 'bus_info'
    },
    CRN: {
      type: 'text_input',
      help: true,
      meta: {
        center: true,
        questions: [{
          data_type: 'text'
        }],
        currency: {
          symbl: "$"
        }
      },
      back: false,
      next: {
        rules: {
          visible: true,
          enabled: ['observation']
        },
        visible: true,
        enabled: false
      },
      module: 'bus_info'
    },
    textN: {
      type: 'text_input',
      help: true,
      meta: {
        center: true,
        questions: [{
          data_type: 'number',
          validation: {
            rule: '^[0-9]+$',
            empty: 'Please enter valid number',
          }
        }]
      },
      back: false,
      next: {
        rules: {
          visible: true,
          enabled: ['observation']
        },
        visible: true,
        enabled: false
      },
      module: 'bus_info'
    },
    rg: {
      type: "radio_group",
      module: "bus_info",
      meta: {
        title: {
          alignment: 'left'
        },
        center: true,
        options: [
          {
            value: 1
          },
          {
            value: 2
          }
        ]
      },
      help: true,
      back: false,
      next: {
        visible: true,
        enabled: false,
        rules: {
          visible: true,
          enabled: [
            "observation"
          ]
        }
      }
    },
  },
  skip_keys: {
    advert_6months: {
      values: ["2"],
      targets: ['advert_type_6months']
    }
  },
  screen_timers: [

  ],
  special_timers: [

  ],
  screen_submit:true
};
