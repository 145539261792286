import React, { useState,useContext } from "react";

import Select from "../../components/Select/Select";

import {extract_text} from '../../helpers';

import {loadImage} from '../../helpers/image_loader'
import {LocaleContext} from '../../context/LocalContext';
import _get from 'lodash.get';

import {CenterContent, GlobalBaseStyle,Question} from '../../assets/styles/BaseStyle';
import shuffleArray from '../../lib/shuffleArray'
const DropDown = ({onChangeHandler,options,observation,title,image}) => {
    const translation = useContext(LocaleContext);
  const [selected, setSelected] = useState(options[0].value);
  const [selectedLable, setLable] = useState(options[0].label);
  const selector = {
    label: selectedLable,
    options: shuffleArray(options),
    value: selected,
    responseHandler: (resp) => {
      if (resp.value) {
        const opt = options.filter((v) => v.value === resp.value);
        setLable(opt[0].label);
        setSelected(resp.value);
        if (typeof onChangeHandler === "function") {
            onChangeHandler({ value: resp.value, label: opt[0].label });
          }
      }
    },
  };
  return (
    <>
     <GlobalBaseStyle />
    <CenterContent >
   { title && <Question alignment={title.alignment}>{extract_text(title) || _get(translation, `${observation}.title`)}</Question>}   
      
      <Select {...selector} />
      {image &&  <img src={loadImage(image)} alt={extract_text(title) || _get(translation, `${observation}.title`)}/>}
      </CenterContent>
    </>
  );
};
export default DropDown;
