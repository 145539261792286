import styled from 'styled-components';

export const ServeyCancel = styled.div`
  margin: 20px 0;
  font-size: 32px;
`;
export const ServeyCancelDesc = styled.div`
  margin: 10px 0;
  font-size: 16px;
`;

export const ServeyCancelContainer = styled.div`
  text-align: center;
  font-size: 18px;
  margin: 20px 0;
  img {
    width: 100%;
  }
  @media (max-width:575px) and (min-width:320px){
    margin: 10% 0%;
    img {
      width: 100%;
    }
  } 
`;