import React from "react";
import { IntlProvider, FormattedMessage } from "react-intl";
import useLanguage from "../../hooks/useLanguage";
import { Main } from './style';


function AlreadyTaken() {
    const { messages, locale, setLocale } = useLanguage()

    return messages ? (
        <IntlProvider locale={locale} messages={messages}>
            <App locale={locale} direction="ltr" onLocaleChange={(locales) => setLocale(locales)} />
        </IntlProvider>
    ) : null;
}
export default AlreadyTaken;

function App({ _locale, _direction, _onLocaleChange }) {
    return (
        <Main>
            <h1 >
                <FormattedMessage id="alredyTaken" />
            </h1>
            <h4 >
                <FormattedMessage id="alredySubmitted" />
            </h4>
            <h4>
                <FormattedMessage id="closeThisWindow" />
            </h4>
        </Main>
    );
}
