import jwtDecode from "jwt-decode";

export default class JwtHelper {
    constructor(token) {
        this.token = token
        this._decoded = jwtDecode(token)
    }
    get decoded() {
        return this._decoded
    }
}